import React, {useState} from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import {auth} from "../../context/Config";
import '../../assets/style/Login.css';
import logo from '../../assets/images/login-logo.png';
import '../../assets/style/index.css';
import ReCAPTCHA from "react-google-recaptcha";
import { fetchWithAuth } from '../../utils/authentication/authenticationUtils';
import { useNavigate } from 'react-router-dom';
import { sendForgotPassword } from '../../utils/notifications/notificationsUtils';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null);
    const navigate = useNavigate();

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    async function fetchAndStoreTimezone() {
        try {
            const response = await fetchWithAuth(process.env.REACT_APP_BACKEND_URL + '/get-timezone');
            if (!response.ok) {
                throw new Error('Failed to fetch timezone');
            }
            const data = await response.json();
            const timezone = data.timezone;

            localStorage.setItem('timeZone', timezone);
        } catch (error) {
            console.error('Error fetching timezone:', error);
            const defaultTimezone = 'America/New_York'; // Default to Eastern Time
            localStorage.setItem('timeZone', defaultTimezone);
        }
    };

    const handleResetPassword = async () => {
        if (!email) {
            alert('Please enter your email address.');
            return;
        }

        try {
            const response = await sendForgotPassword(email);

            if (!response) {
              throw new Error('Failed to generate password reset link');
            }

            alert('Password reset email sent! Check your inbox.');
        } catch (error) {
            console.error('Error sending password reset email:', error);
            alert('Failed to send password reset email. Please try again.');
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();
    
        if (!captchaValue) {
            alert('Please verify you are not a robot.');
            return;
        }
    
        try {
            // Step 1: Verify reCAPTCHA
            const recaptchaResponse = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/api/verifyRecaptcha`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ recaptchaToken: captchaValue }),
            });
    
            const recaptchaData = await recaptchaResponse.json();
            if (!recaptchaData.success) {
                alert('Failed reCAPTCHA verification. Please try again.');
                return;
            }
    
            // Step 2: Firebase sign-in
            let userCredential;
            try {
                userCredential = await signInWithEmailAndPassword(auth, email, password);
            } catch (firebaseError) {
                if (firebaseError.code === 'auth/invalid-credential' || firebaseError.code === 'auth/user-not-found' || firebaseError.code === 'auth/wrong-password') {
                    alert('Invalid email or password. Please try again.');
                } else {
                    alert('An unexpected error occurred during login. Please try again.');
                }
                window.location.reload();
                setCaptchaValue(null); // Reset captcha on error
                return;
            }
    
            const idToken = await userCredential.user.getIdToken();
    
            // Step 3: Send ID token to backend for cookie setting
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/api/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ idToken })
            });
    
            const data = await response.json();
    
            if (data.success) {
                localStorage.setItem('userEmail', email);
                fetchAndStoreTimezone();
                navigate('/');
            } else {
                alert("Invalid credentials. Try again.");
                window.location.reload();
                setCaptchaValue(null);
            }
        } catch (error) {
            console.error('Error during login:', error);
            alert('Login failed. Please try again.');
            window.location.reload();
            setCaptchaValue(null);
        }
    };

    return (
        <div className="login-container" style={{display: 'flex', flexDirection: 'column'}}>
            <img src={logo} alt="Logo" className="login-logo" style={{height: '70px', width: '400px', zIndex: 1, margin: '65px'}} />

            <form onSubmit={handleLogin} className="log-in-form">

                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    className="input-field"
                    style={{padding: '20px', margin: '15px', marginTop: "40px"}}
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="input-field"
                    style={{padding: '20px', margin: '15px'}}
                />
                <p onClick={handleResetPassword} style={{color: '#61C5CA', textDecoration: 'underline', cursor: 'pointer', marginTop: '-5px', marginLeft: '18px'}}>
                    Forgot Password?
                </p>

                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SECRET_KEY}
                    onChange={handleCaptchaChange}
                    style={{padding: '10px', margin: '5px'}}
                />
                <button type="submit" className="log-in-button">Login</button>
            </form>
        </div>

    );
};

export default Login;
