import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { handleInventoryReportDownload } from "../../utils/reports/reportsUtils"; // Import the function
import loadingSpinner from "../../assets/images/loading.gif";
import { fetchUser } from "../../utils/users/userUtils"; // Import the necessary functions
import { fetchLocationPricingStyle } from "../../utils/locations/locationsUtils"; // Import the necessary functions
import {
    newDate,
    formatDateToYYYYMMDD,
} from "../../utils/time_zones/TimeZones";

const InventoryReportModal = ({ locationName, isOpen, onClose }) => {

    const today = newDate();
    const [selectedDate, setSelectedDate] = useState(formatDateToYYYYMMDD(today));
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState("");
    const [pricingStyle, setPricingStyle] = useState("average_pricing");

    const fetchUserData = async () => {
        try {
            // Fetch the user info
            await fetchUser(setUser);
            const fetchedPricingStyle = await fetchLocationPricingStyle(locationName);
            setPricingStyle(fetchedPricingStyle);
        } catch (error) {
            console.error("Error fetching user or pricing data:", error);
        }
    };

    // Fetch user information on component mount
    useEffect(() => {
        if (locationName) {
            fetchUserData();
        }
    }, [locationName]); // Refetch when locationName changes

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const onReportDownload = async () => {
        setIsLoading(true);
        try {
            await handleInventoryReportDownload(
            selectedDate,
            user?.username,
            user?.name,
            locationName,
            pricingStyle,
            setIsLoading
            );
        } catch (error) {
            console.error("Error downloading report:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Modal
            className="modal-content"
            isOpen={isOpen} // This is where the modal's open state is managed
            onRequestClose={onClose} // Close modal callback function passed as prop
            style={{
                content: {
                width: "320px",
                height: "40%",
                },
            }}
            >
            <h2>Select Date</h2>
            <p>
                Generated report will fetch the inventory data for the start of
                the selected date.
            </p>
            <input
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
            />
            <p></p>
            <div style={{ display: "flex", alignItems: "center" }}>
                <button
                onClick={onReportDownload}
                style={{
                    border: "3px solid #31bac1",
                    backgroundColor: "#31bac1",
                    padding: "5px",
                }}
                >
                Download Report
                </button>

                {isLoading && (
                <img
                    src={loadingSpinner}
                    alt="loading-spinner"
                    style={{
                    height: "20px",
                    marginLeft: "10px",
                    }}
                />
                )}
            </div>
            <p></p>
            <button
                onClick={onClose} // Close the modal when this button is clicked
                style={{
                border: "3px solid #31bac1",
                backgroundColor: "#31bac1",
                padding: "5px",
                }}
            >
                Close
            </button>
            </Modal>
        </div>
    );
};

export default InventoryReportModal;