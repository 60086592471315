
import React from 'react';
import Modal from 'react-modal';
import ModalHeader from '../../components/ModalHeader';
import InvoiceDetailsContent from './InvoiceDetailsContent';

const InvoiceDetailsModal = ({isOpen, setIsOpen, onClose, invoice}) => {
    const handleClose = () => {
        onClose();
        setIsOpen(false);
    };

    return (
        <Modal
            overlayClassName="white-modal-overlay"
            className="white-modal"
            isOpen={isOpen}
            onRequestClose={handleClose}
            style={{
                overlay: {
                },
                content: {
                    width: '65vw',
                }
            }}
        >
            <ModalHeader
                description={'Invoice details for'}
                title={`Invoice #${ invoice.invoice_id }`}
                showExit={true}
                onClose={handleClose} />

            <InvoiceDetailsContent invoice={invoice} />
        </Modal>
    );
};

export default InvoiceDetailsModal;