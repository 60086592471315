import { axiosWithAuth } from '../authentication/authenticationUtils';

export const handleInventoryReportDownload = async (selectedDate, username, name, locationName, pricingStyle, setIsLoading) => {
    setIsLoading(true);

    // Extracting year, month, and day from the selectedDate string ('yyyy-mm-dd')
    const [year, month, day] = selectedDate.split("-");

    // Formatting the date as 'month_day_year'
    const formattedDate = `${month}_${day}_${year}`;

    const selectedDateTime = new Date(year, month - 1, day);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (selectedDateTime > today) {
      alert("Please select a date previous to today's date.");
      setIsLoading(false);
      return;
    }

    const twoMonthsAgo = new Date(today);
    twoMonthsAgo.setMonth(today.getMonth() - 2);
    twoMonthsAgo.setDate(today.getDate() + 1);

    if (selectedDateTime <= twoMonthsAgo) {
      alert("Please select a date within the last 60 days.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_BACKEND_URL}/download-inventory-report?locationName=${locationName}&username=${username}&name=${name}&date=${formattedDate}&pricingStyle=${pricingStyle}&today=${today}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `inventory_report_${locationName}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      alert(
        "Please note that reports can only be retrieved for dates within the last 60 days. For more information, contact Medvelle."
      );
      setIsLoading(false);
    }

    setIsLoading(false);
};