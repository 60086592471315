import Fuse from 'fuse.js';
import {newDate} from '../time_zones/TimeZones';

export const fuzzySearch = (list, query, fields, threshold) => {
    if (!query) return list;
    if (!threshold) {
        threshold = .3;
    }
    const options = {
        includeScore: true,
        shouldSort: true,
        findAllMatches: true,
        threshold: threshold,
        keys: fields,
        ignoreLocation: true,
    };
    const fuse = new Fuse(list, options);
    const result = fuse.search(query);
    return result.map(item => item.item);
};

export const filterByTime = (data, timeFilter, field) => {
    switch (timeFilter) {
        case 'last6months':
            return data.filter(dataPoint => isWithinLastMonths(dataPoint, 6, field));
        case 'last3months':
            return data.filter(dataPoint => isWithinLastMonths(dataPoint, 3, field));
        case 'lastmonth':
            return data.filter(dataPoint => isWithinLastMonths(dataPoint, 1, field));
        case 'thismonth':
            return data.filter(dataPoint => isWithinThisMonth(dataPoint, field));
        case 'nextmonth':
            return data.filter(dataPoint => isWithinNextMonths(dataPoint, 1, field));
        case 'next3months':
            return data.filter(dataPoint => isWithinNextMonths(dataPoint, 3, field));
        case 'next6months':
            return data.filter(dataPoint => isWithinNextMonths(dataPoint, 6, field));
        default:
            return data;
    }
};

const isWithinLastMonths = (dataPoint, months, field) => {
    const today = newDate();
    const dataDate = newDate(dataPoint[field]);

    const diffInMonths = (today.getFullYear() - dataDate.getFullYear()) * 12 + today.getMonth() - dataDate.getMonth();
    return diffInMonths <= months && (diffInMonths > 0 || (diffInMonths === 0 && dataDate.getDay() < today.getDay()));
};

const isWithinThisMonth = (dataPoint, field) => {
    const today = newDate();
    const dataDate = newDate(dataPoint[field]);
    return (
        dataDate.getFullYear() === today.getFullYear() &&
        dataDate.getMonth() === today.getMonth()
    );
};


const isWithinNextMonths = (dataPoint, months, field) => {
    const today = newDate();
    const dataDate = newDate(dataPoint[field]);

    const diffInMonths = (dataDate.getFullYear() - today.getFullYear()) * 12 + dataDate.getMonth() - today.getMonth();
    return diffInMonths <= months && (diffInMonths > 0 || (diffInMonths === 0 && dataDate.getDay() > today.getDay()));
};