import { fetchWithAuth } from '../authentication/authenticationUtils';

/**
 * Function to fetch the username.
 * @param {Function} setUser - A callback function to set the user name in the state.
 * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the fetch request.
 */
export const fetchUser = (setUser, signal = undefined) => {
    const userEmail = localStorage.getItem("userEmail");

    if (!userEmail) return Promise.resolve(null); // Return null if no email is found

    return fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/get-name?user_email=${ userEmail }`, {
        ...(signal ? {signal} : {}),
    })
        .then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        })
        .then(data => {
            setUser(data);
            return data; // Return the fetched user data
        })
        .catch(error => {
            if (error.name !== "AbortError") {
                console.error("Error fetching username:", error);
            }
            return null; // Return null in case of an error
        });
};

/**
 * Function to fetch user information.
 * @param {string} locationName - The name of the location.
 * @param {Function} callBack - A callback function to handle the fetched data.
 * @param {AbortSignal} [signal] - Optional AbortSignal to cancel the fetch request.
 */
export const fetchUserInfo = async ({locationName, callBack, signal = undefined}) => {
    try {
        const userEmail = localStorage.getItem('userEmail');

        if (userEmail) {
            const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/get-user-info`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({user_email: userEmail, location_name: locationName}),
                ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (callBack) callBack(data);
            return data;
        }
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching user information:', error);
        }
        return false;
    }
};

/**
 * Function to fetch the username based on the user's email stored in local storage.
 * @param {Function} setUsername - A callback function to set the username in the state.
 */
export const fetchUsername = async (setUsername, signal = undefined) => {
    try {
        // Get the user's email from local storage
        const userEmail = localStorage.getItem('userEmail');

        if (userEmail) {
            // Fetch the username from the API
            const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/get-username?user_email=${ userEmail }`, {
                method: 'GET',
                ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Parse the response JSON
            const data = await response.json();

            // Set the username in the state
            setUsername(data.username);
        }
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching username:', error);
        }
    }
};

/**
 * Fetches the list of internal users from the backend API and updates the user list state.
 *
 * @param {Function} setUserList - Function to update the state of the user list in the component.
 */
export const fetchUserList = async (setUserList) => {
    try {
        const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/user-list`);
        const data = await response.json();
        setUserList(data);
    } catch (error) {
        console.error('Error fetching user list:', error);
    }
  };

/**
* Fetches the list of users from the backend API and updates the user list state.
*
* @param {Function} setUserList - Function to update the state of the user list in the component.
*/
export const fetchUserListAll = async (setUserList) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/user-list-all`);
        const data = await response.json();
        setUserList(data);
    } catch (error) {
        console.error('Error fetching user list:', error);
    }
};

export const fetchUserDetails = async ({locationName, username, setUserDetails, signal = undefined}) => {
    try {
        const userEmail = localStorage.getItem('userEmail');

        if (userEmail) {
            const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/user-info`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({username: username, location_name: locationName}),
                ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setUserDetails(data)
            return data;
        }
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching user details:', error);
        }
        return false;
    }
};