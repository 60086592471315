import React, { useState } from 'react';

const OrderingBudgetModal = ({
    processedOrders,
    remainingBudget,
    budget,
    processedPercent,
    unprocessedPercent,
    unprocessedOrders,
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const barColor = remainingBudget >= 0 ? '' : '#e9453b';

    const handleMouseEnter = () => {
        setIsTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setIsTooltipVisible(false);
    };

    const remainingText = remainingBudget >= 0
    ? `Remaining`
    : `Over Budget`;

    return (
        <div
            style={{
                backgroundColor: '#EEEEEE',
                marginTop: '-10px',
                borderRadius: '10px',
                width: '300px',
                padding: '10px',
                boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
            }}
            className="progress-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={{ marginTop: '0', marginBottom: '0', color: 'black', fontSize: '14px'}}>{remainingText}</p>
                <p style={{ marginTop: '0', marginBottom: '0', color: 'black', fontSize: '14px'}}>Monthly Budget</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <p style={{ fontWeight: 'bold',  marginTop: '2px', marginBottom: '0', fontSize: '14px' }}>${Math.floor(Math.abs(remainingBudget)).toLocaleString()}</p>
                <p style={{ fontWeight: 'bold', marginTop: '2px', marginBottom: '0', fontSize: '14px'}}>${Math.floor(budget).toLocaleString()}</p>
            </div>
            <div className="progress-bar" style={{height: '8px', display: 'flex', backgroundColor: '#F3F4F6', borderRadius: '5px' }}>
                <div className="progress-processed" style={{ width: `${processedPercent}%`, backgroundColor: barColor, height: '100%', borderRadius: '5px 0 0 5px' }}></div>
                <div className="progress-unprocessed" style={{ width: `${unprocessedPercent}%`, backgroundColor: barColor, height: '100%', borderRadius: '0 5px 5px 0'}}></div>
            </div>
            

            {isTooltipVisible && (
                <div className="tooltip">
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: remainingBudget <= 0 ? '#e9453b' : '#f49c7d',
                        marginRight: '5px'
                      }}
                    ></div>
                    <p style={{ color: 'black', margin: 0, fontSize: '13px' }}>
                      Processed: ${Math.round((processedOrders ? processedOrders : 0)).toLocaleString()}
                    </p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: remainingBudget <= 0 ? '#e9453b' : '#fad5c0',
                        marginRight: '5px'
                      }}
                    ></div>
                    <p style={{ color: 'black', margin: 0, fontSize: '13px' }}>
                      Pending: ${Math.round((unprocessedOrders ? unprocessedOrders : 0)).toLocaleString()}
                    </p>
                  </div>
                  {remainingBudget > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                      <div style={{ width: '10px', height: '10px', backgroundColor: '#e0e0e0', marginRight: '5px' }}></div>
                      <p style={{ color: 'black', margin: 0, fontSize: '13px' }}>
                        Remaining: ${Math.round((remainingBudget ? remainingBudget : 0)).toLocaleString()}
                      </p>
                    </div>
                  )}
                </div>
              )}
        </div>
    );
};


export default OrderingBudgetModal;