
import React, {useEffect, useState, useRef} from 'react';
import Modal from 'react-modal';
import ModalHeader from '../../components/ModalHeader';
import {formatDateText} from '../../utils/time_zones/TimeZones';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltUp, faLongArrowAltDown} from '@fortawesome/free-solid-svg-icons';
import {calculateFilteredDeltaSumExplained, calculateFilteredDeltaSumUnexplained} from '../../utils/flags/flagInfo';

const FlagDetailsModal = ({isOpen, setIsOpen, onClose, flagToResolve, flagDetails, onResolveClick, permissionCode}) => {
    const tableContainerRef = useRef(null);

    const handleClose = () => {
        onClose();
        setIsOpen(false);
    };

    return (
        <Modal
            overlayClassName="white-modal-overlay"
            className="white-modal"
            isOpen={isOpen}
            onRequestClose={handleClose}
            style={{
                overlay: {
                },
                content: {

                }
            }}
        >
            <ModalHeader
                description={'Flag details for'}
                title={`${ flagToResolve.product_name } ${ flagToResolve.product_description ? ` - ${ flagToResolve.product_description }` : '' }`}
                showExit={true}
                onClose={handleClose} />

            <div style={{margin:'20px'}}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            border: '2px solid #31bac1',
                            display: 'flex',
                            justifyContent: 'left',
                            flexDirection: 'column',
                            width: '50%',
                            boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <table style={{
                            backgroundColor: '#fffff',
                            width: '100%',
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                            position: 'relative', // Ensure positioning context
                            tableLayout: 'fixed', // Ensure fixed table layout
                        }}>
                            <tbody>
                                <tr style={{fontSize: '13px', backgroundColor: 'rgba(245, 251, 240, 1)'}} >
                                    <td className='table-row' style={{padding: '5px', textAlign: 'left', width: '70%'}}><b>Last verified quantity:</b></td>
                                    <td className='table-row' style={{padding: '5px', textAlign: 'center', width: '15%'}}></td>
                                    <td className='table-row' style={{padding: '5px', textAlign: 'center', width: '15%'}}>
                                        {flagDetails && flagDetails.find(detail => detail.flag === 0)?.quantity_after.toFixed(1)}
                                    </td>
                                </tr>
                                <tr style={{fontSize: '13px', backgroundColor: '#ffffff'}} >
                                    <td className='table-row' style={{padding: '5px', textAlign: 'left', width: '70%'}}><b>Explained change:</b></td>
                                    <td className='table-row' style={{padding: '5px', textAlign: 'left', width: '15%'}}>
                                        {flagDetails && calculateFilteredDeltaSumExplained(flagDetails) >= 0 ? (
                                            <span style={{float: 'left', color: '#A9A9A9'}}>
                                                <FontAwesomeIcon icon={faLongArrowAltUp} />
                                            </span>
                                        ) : (
                                            <span style={{float: 'left', color: '#A9A9A9'}}>
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                            </span>
                                        )}
                                        <span style={{float: 'right'}}>{flagDetails && Math.abs(calculateFilteredDeltaSumExplained(flagDetails)).toFixed(1)}</span>
                                    </td>
                                    <td className='table-row' style={{padding: '5px', textAlign: 'center', width: '15%'}}></td>
                                </tr>
                                <tr style={{fontSize: '13px', backgroundColor: 'rgba(253, 244, 244, 1)'}} >
                                    <td className='table-row' style={{padding: '5px', textAlign: 'left', width: '70%'}}><b>Unexplained change:</b></td>
                                    <td className='table-row' style={{padding: '5px', textAlign: 'left', width: '15%'}}>
                                        {flagDetails && calculateFilteredDeltaSumUnexplained(flagDetails) >= 0 ? (
                                            <span style={{float: 'left', color: 'rgba(93, 158, 82, 1)'}}>
                                                <FontAwesomeIcon icon={faLongArrowAltUp} />
                                            </span>
                                        ) : (
                                            <span style={{float: 'left', color: 'rgba(207, 100, 84, 1)'}}>
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                            </span>
                                        )}
                                        <span style={{float: 'right'}}>{flagDetails && Math.abs(calculateFilteredDeltaSumUnexplained(flagDetails)).toFixed(1)}</span>
                                    </td>
                                    <td className='table-row' style={{padding: '5px', textAlign: 'center', width: '15%'}}></td>
                                </tr>
                                <tr style={{fontSize: '13px', backgroundColor: '#ffffff'}} >
                                    <td className='table-row' style={{padding: '5px', textAlign: 'left', width: '70%'}}><b>Ending quantity:</b></td>
                                    <td className='table-row' style={{padding: '5px', textAlign: 'center', width: '15%'}}></td>
                                    <td className='table-row' style={{padding: '5px', textAlign: 'center', width: '15%'}}>
                                        {flagDetails && flagDetails.length > 0 ? flagDetails[flagDetails.length - 1].quantity_after.toFixed(1) : ''}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <br />

                    <div
                        ref={tableContainerRef}
                        style={{
                            border: '2px solid #31bac1',
                            display: 'flex',
                            justifyContent: 'left',
                            flexDirection: 'column',
                            width: '100%',
                            height: 'auto',
                            maxHeight: '310px', // Set a fixed height
                            overflowY: 'auto', // Enable vertical scrolling
                            boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <table style={{
                            backgroundColor: '#fffff',
                            width: '100%',
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                            position: 'relative', // Ensure positioning context
                            tableLayout: 'fixed', // Ensure fixed table layout
                        }}>
                            <thead style={{
                                position: 'sticky', // Stick the header
                                top: 0, // Stick to the top of the table
                                backgroundColor: '#ffffff', // Background to cover underlying rows
                            }}>
                                <tr style={{fontSize: '14px', backgroundColor: '#fffff', color: '#00000', }}>
                                    <th style={{borderBottom: '2px solid black', padding: '5px', textAlign: 'center', width: '24%'}}>Date</th>
                                    <th style={{borderBottom: '2px solid black', padding: '5px', textAlign: 'center', width: '24%'}}>Event</th>
                                    <th style={{borderBottom: '2px solid black', padding: '5px', textAlign: 'center', width: '24%'}}>User</th>
                                    <th style={{borderBottom: '2px solid black', padding: '5px', textAlign: 'center', width: '7%'}}>Change</th>
                                    <th style={{borderBottom: '2px solid black', padding: '5px', textAlign: 'center', width: '14%'}}>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {flagDetails && flagDetails.map((item, index) => (
                                    <tr style={{fontSize: '13px', backgroundColor: (item.flag ? 'rgba(253, 244, 244, 1)' : (item.origin === 'Spotcheck' || item.origin === 'Edit Inventory' ? 'rgba(245, 251, 240, 1)' : '#ffffff'))}} >
                                        <td className='table-row' style={{padding: '5px', textAlign: 'center', width: '24%'}}>{item.processed_origin !== 'Product Usage' ? formatDateText(item.date_time) : '...'}</td>
                                        <td className='table-row' style={{padding: '5px', textAlign: 'center', width: '24%'}}>{item.processed_origin}</td>
                                        <td className='table-row' style={{padding: '5px', textAlign: 'center', width: '24%'}}>{item.username}</td>
                                        <td className='table-row' style={{padding: '5px', textAlign: 'left', width: '7%'}}>
                                            {(item.origin === 'Spotcheck' || item.origin === 'Edit Inventory') ? (
                                                ''
                                            ) : (
                                                <>
                                                    {item.delta >= 0 ? (
                                                        <span style={{float: 'left', color: (item.flag ? 'rgba(93, 158, 82, 1)' : '#A9A9A9')}}>
                                                            <FontAwesomeIcon icon={faLongArrowAltUp} />
                                                        </span>
                                                    ) : (
                                                        <span style={{float: 'left', color: (item.flag ? 'rgba(207, 100, 84, 1)' : '#A9A9A9')}}>
                                                            <FontAwesomeIcon icon={faLongArrowAltDown} />
                                                        </span>
                                                    )}
                                                    <span style={{float: 'right'}}>{Math.abs(item.delta).toFixed(1)}</span>
                                                </>
                                            )}
                                        </td>
                                        <td className='table-row' style={{padding: '5px', textAlign: 'center', width: '14%'}}>{item.quantity_after.toFixed(1)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <br/>
                <div style={{display: 'flex', gap: '8px', justifyContent:'center'}}>
                    <button onClick={handleClose}
                        className='action-button'
                    >
                        Close
                    </button>

                    <button onClick={onResolveClick}
                        className='support-button-accent'
                        style={{
                            fontSize: 'small'
                        }}
                        disabled={permissionCode === 27}
                    >
                        Resolve
                    </button>
                </div>
            </div>            
        </Modal>
    );
};

export default FlagDetailsModal;