import React, {useEffect, useState, useRef} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/Card';
import {fuzzySearch} from '../utils/tables/searchUtils';
import PromotionCarousel from '../features/suppliers/PromotionCarousel';
import SupplierModal from '../features/suppliers/SupplierModal';
import SupportForm from '../features/support/SupportForm';
import IconTooltip from '../components/IconTooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { fetchWithAuth } from '../utils/authentication/authenticationUtils';

const Suppliers = ({locationName, permissionCode}) => {
    const contentRef = useRef();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [promotionsOnly, setPromotionsOnly] = useState(false);
    const [clientSuppliers, setClientSuppliers] = useState([]);
    const [filteredClientSuppliers, setFilteredClientSuppliers] = useState([]);
    const [filteredPromotions, setFilteredPromotions] = useState([]);
    const [promotions, setPromotions] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);
    const [clickedSupplier, setClickedSupplier] = useState({});
    const [showContact, setShowContact] = useState(false);
    const promotionDisclaimer = 'Medvelle Community Promotions are a beta feature available to select users and updated monthly. Medvelle aims to provide helpful and informative content but does not guarantee the accuracy, completeness, or timeliness of the Community Promotions listed. Community Promotions are subject to each suppliers Terms and may change at any time. For the most current information, we recommend directly engaging with your Sales Representatives and actively soliciting available promotions.'
    
    useEffect(() => {
        fetchClientSuppliers();
        fetchPromotions();
        contentRef.current.style.marginLeft = '250px';
        contentRef.current.style.marginRight = '50px';
    }, [locationName]);

    useEffect(() => {
        setFilteredClientSuppliers(fuzzySearch(clientSuppliers, searchTerm, ['supplier_name']).filter(item => (promotionsOnly === 'true' ? item.promotions > 0 : true)).filter(item => selectedSupplier ? selectedSupplier === item.supplier_id : true));
        setFilteredPromotions(fuzzySearch(promotions, searchTerm, ['supplier_name', 'promotion_description', 'product_name']).filter(item => selectedSupplier ? selectedSupplier === item.supplier_id : true));
    }, [searchTerm, clientSuppliers, selectedSupplier, promotionsOnly, promotions]);

    const toggleContactInfo = () => {
        setShowContact(!showContact);
    };

    const fetchPromotions = async () => {
        try {
            const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/promotions`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({location_name: locationName})
            });
            if (response.ok) {
                const data = await response.json();
                setPromotions(data.map(item => (
                    {
                        ...item,

                    })));
            }
        } catch (error) {
            console.error('Error updating supplier:', error);
        }
    };


    const fetchClientSuppliers = async () => {
        try {
            const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/client-suppliers`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({location_name: locationName})

            });
            const data = await response.json();
            setClientSuppliers(data);
            return data;
        } catch (error) {
            console.error('Error fetching client suppliers data:', error);
        }
    };



    return (
        <div ref={contentRef} style={{textAlign: 'left'}}>
            <div style={{textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h1
                        style={{
                            fontSize: '40px',
                            fontWeight: 'bold',
                            color: '#87D3D7',
                            marginLeft: '20px',
                        }}
                    >
                        Supplier Page
                    </h1>
                </div>
                <button className="support-btn" onClick={toggleContactInfo} style={{backgroundColor: '#87D3D7', marginRight: '-4px'}}>
                    <h1 style={{fontSize: '20px'}}>?</h1>
                </button>
                {showContact && (
                    <SupportForm callBack={setShowContact} locationName={locationName} permissionCode={permissionCode} />
                )}
            </div>

            <div style={{display: 'flex', justifyContent: 'left', marginBottom: '20px', marginTop: '20px'}}>
                <input
                    type="text"
                    className='select-box'
                    placeholder="Search Supplier Name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{marginRight: '20px', marginLeft: '20px', border: '1px solid #87D3D7', padding: '10px', boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', fontStyle: 'italic', width: '300px'}}
                />

                <select className='select-box' style={{padding: '8px', marginRight: '20px'}} value={selectedSupplier} onChange={(e) => setSelectedSupplier(parseInt(e.target.value))}>
                    <option value="">All Suppliers</option>
                    {[...clientSuppliers]
                        .sort((a, b) => a.supplier_name.localeCompare(b.supplier_name))
                        .map((supplier) => (
                            <option key={supplier.supplier_id} value={supplier.supplier_id}>
                                {supplier.supplier_name}
                            </option>
                        ))}
                </select>
                <select className='select-box' style={{padding: '8px'}} value={promotionsOnly} onChange={(e) => setPromotionsOnly(e.target.value)}>
                    <option value={false}>Any Promotion Status</option>
                    <option value={true}>Offering Promotion</option>
                </select>
            </div>

            <div style={{
                display: 'flex',
                flexWrap: 'wrap', // Allows wrapping to the next line
                padding: '20px', // Optional padding around the card container
                height: 'auto',
                transition: 'height 0.5s ease', // Transition for height changes
                gap: '20px',
                marginRight: '0px',
                justifyContent: 'flex-start'
            }}>
                {filteredClientSuppliers.length === 0 && (
                    <div>No Suppliers</div>
                )}
                {filteredClientSuppliers.slice(0, showAll ? filteredClientSuppliers.length : 6).map(item => (
                    <>


                        <Card key={item.product_id} title={item.supplier_name} buttonTitle={'View Supplier'} banner={item.promotions > 0 ? 'Promotion Available' : ''} onClick={() => {setClickedSupplier(item); setIsSupplierModalOpen(true);}} paragraphs={[
                            <div style={{display: 'flex', justifyContent: 'space-between', }}>
                                <div>
                                    <div style={{color: 'grey'}}>Total Spend</div>
                                    <div>${parseInt(item.total_spent?.toFixed(0)).toLocaleString()}</div>
                                </div>
                                <div>
                                    <div style={{color: 'grey'}}>Total Orders</div>
                                    <div>{item.total_orders}</div>
                                </div>
                                <div>
                                    <div style={{color: 'grey'}}>Promotions</div>
                                    <div style={{color: item.promotions > 0}}>{item.promotions}</div>
                                </div>

                            </div>
                        ]}
                        />
                    </>
                ))}
            </div>

            {(filteredClientSuppliers.length > 6) && (
                <button className='action-button' style={{marginLeft: '20px'}} onClick={() => setShowAll(!showAll)}>{showAll ? 'Hide Suppliers' : 'Show All Suppliers'}</button>
            )}

            <h2 style={{marginLeft: '20px', marginBottom:'0px', textAlign: 'left'}}>Community Promotions
                <IconTooltip icon={
                    <FontAwesomeIcon
                        icon={faInfoCircle} // Toggle between lock and unlock icons
                        className={'icon-hover-accent'}

                    />}
                    hoverText={promotionDisclaimer}
                />
            </h2>
            <div style={{paddingLeft: '10px'}}>
                <PromotionCarousel cards={filteredPromotions} waitTime={3000} locationName={locationName} permissionCode={permissionCode}/>
            </div>

            {isSupplierModalOpen && (
                <SupplierModal supplier={clickedSupplier} isOpen={isSupplierModalOpen} setIsOpen={setIsSupplierModalOpen} promotions={promotions.filter(item => (item.supplier_id === clickedSupplier.supplier_id))} onClose={() => { }} locationName={locationName} permissionCode={permissionCode} fetchClientSuppliers={fetchClientSuppliers}/>
            )}

            <ToastContainer />
        </div>
    );
};

export default Suppliers;
