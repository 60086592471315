import React, { useState, useEffect, createContext, useContext } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from './Config';
import { clearCookies, checkAuthStatus } from '../utils/authentication/authenticationUtils';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loginStatus, setLoginStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [referrer, setReferrer] = useState('/sign-in');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignOut = () => {
    signOut(auth).then(() => {
      setLoginStatus(false);
      localStorage.clear();
      clearCookies();
      navigate('/sign-in');
      console.log("Logged out successfully");
    }).catch((error) => {
      console.error("Error signing out", error);
    });
  };

  useEffect(() => {
    // Initialize Firebase onAuthStateChanged listener
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user && user.email && user.email.trim() !== '') {
          // Check if session cookie is valid through backend
          const isValidSession = await checkAuthStatus();

          if (isValidSession) {
            setLoginStatus(true);
            localStorage.setItem('userEmail', user.email);
          } else {
            alert('Your session is no longer valid. Please log in again.');
            handleSignOut();
          }

        } else {
          handleSignOut();
        }
      } catch (error) {
        alert('Your session is no longer valid. Please log in again.');
        console.error('Error validating session:', error);
        handleSignOut();
      } finally {
        // Ensure loading state is set to false regardless of outcome
        setLoading(false);
      }
    });

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (location?.pathname && location?.pathname !== '/sign-in') {
      setReferrer(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (loginStatus) {
      navigate(referrer);
    }
  }, [loginStatus]);

  return (
    <AuthContext.Provider value={{ loginStatus, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);