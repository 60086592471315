import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Title } from "chart.js";

// Register chart.js components
ChartJS.register(ArcElement, Tooltip, Title);

// Custom plugin to draw text in the center of the donut hole
const centerTextPlugin = {
    id: "centerText",
    beforeDraw: (chart) => {
        const { ctx, chartArea } = chart;
        if (!chartArea) return; // Avoid drawing if chartArea is undefined

        const centerText = chart.options.plugins.centerText?.text || "";
        const fontSize = chart.options.plugins.centerText?.fontSize || 20;
        const textColor = chart.options.plugins.centerText?.color || "black";

        // Get center of the actual chart (excluding legend)
        const centerX = (chartArea.left + chartArea.right) / 2;
        const centerY = (chartArea.top + chartArea.bottom) / 2;

        ctx.save();
        ctx.font = `bold ${fontSize}px Arial`;
        ctx.fillStyle = textColor;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(centerText, centerX, centerY);
        ctx.restore();
    },
};

// Register the custom plugin
ChartJS.register(centerTextPlugin);

const DonutChart = ({ dataDict, colorMapping = {}, labelMapping = {}, onClick = () => {} }) => {
    const originalLabels = Object.keys(dataDict);
    const [hiddenIndexes, setHiddenIndexes] = useState([]); // Track hidden legend items

    const labels = originalLabels.map((key) => labelMapping[key] || key);
    const dataValues = Object.values(dataDict);

    // Filter out hidden items
    const visibleData = dataValues.map((value, index) => (hiddenIndexes.includes(index) ? 0 : value));

    // Calculate dynamic total
    const totalAmount = visibleData.reduce((sum, total) => sum + total, 0).toLocaleString();
    const centerText = `$${totalAmount}`; // Display updated total in center

    const backgroundColors = originalLabels.map(
        (key, i) => colorMapping[key] || `hsl(${(i * 360) / originalLabels.length}, 70%, 70%)`
    );

    const chartData = {
        labels,
        datasets: [
            {
                data: visibleData,
                backgroundColor: backgroundColors,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const originalLabel = originalLabels[tooltipItem.dataIndex];
                        return `${tooltipItem.label || originalLabel}: $${tooltipItem.raw.toLocaleString()}`;
                    },
                },
            },
            centerText: {
                text: centerText,
                fontSize: 24, // Adjust as needed
                color: "black", // Customizable text color
                fontWeight: "bold",
            },
        },
        cutout: "70%", // Defines the size of the inner hole
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const index = elements[0].index;
                const label = labels[index];
                const value = dataValues[index];
                if (onClick) {
                    onClick({ label, value, index });
                }
            }
        },
        onHover: (event, elements) => {
            const chartCanvas = event.native.target;
            chartCanvas.style.cursor = elements.length > 0 ? "default" : "default";
        },
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "100%", padding: "4px", marginTop: "8px" }}>
            {/* Chart Container */}
            <div style={{ flexGrow: 1, height: "100%" }}>
                <Doughnut id={'spendChart'} data={chartData} options={options} />
            </div>
            
            {/* Scrollable Legend */}
            <div style={{ maxHeight: "200px", overflowY: "auto", minWidth: "150px", paddingLeft: "10px" }}>
                {labels.map((label, index) => (
                    <div 
                        key={index}
                        onClick={() => setHiddenIndexes((prev) =>
                            prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
                        )}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            opacity: hiddenIndexes.includes(index) ? 0.5 : 1,
                        }}
                    >
                        <div 
                            style={{
                                width: "12px",
                                height: "12px",
                                backgroundColor: backgroundColors[index],
                                marginRight: "6px",
                                borderRadius: "50%",
                            }}
                        ></div>
                        {label}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DonutChart;
