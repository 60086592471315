import React, { useState } from 'react';
import Modal from 'react-modal';
import ProgressBar from '../../components/ProgressBar.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { fetchLastSupportTicketId, parseEmails, newSupportTicket, updateSupportTicketConversation} from '../../utils/support/supportUtils';
import {sendEmail} from '../../utils/notifications/notificationsUtils';
import {emailTemplates} from '../../utils/notifications/emailTemplates';
import {fetchUser, fetchUsername} from '../../utils/users/userUtils';
import { useEffect } from 'react';

const ReturnModal = ({ isOpen, onClose, orderDetails, submitReturn }) => {
  const steps = [
    { title: 'Policy' },
    { title: 'Return Reason' },
    { title: 'Return/Replace' },
    { title: 'Additional Details' },
    { title: 'Next Steps' }
  ];

  const [currentStep, setCurrentStep] = useState(1);
  const [reason, setReason] = useState('');
  const [returnOrReplace, setReturnOrReplace] = useState('');
  const [urgency, setUrgency] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [username, setUsername] = useState('');
  const [user, setUser] = useState('');

  useEffect(() => {
    if (isOpen) {
      fetchUsername(setUsername);
      fetchUser(setUser);
    }
  }, [isOpen]);

  const handleNextStep = () => {
    if (currentStep === 1 && !isCheckboxChecked) {
      alert('Please acknowledge the policy before proceeding');
      return;
    }
    if (currentStep === 2 && !reason) {
      alert('Please select a reason');
      return;
    }
    if (currentStep === 3 && !returnOrReplace) {
      alert('Please select if you prefer a return or replacement');
      return;
    }
    if (currentStep === 4 && (!additionalDetails || !urgency)) {
      alert('Please fill out the details and urgency');
      return;
    }
    if (currentStep === steps.length - 1) {
      handleReturn();
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBackStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleModalClose = () => {
    setCurrentStep(1);
    setReason('');
    setReturnOrReplace('');
    setUrgency('');
    setAdditionalDetails('');
    setIsCheckboxChecked('');
    onClose();
  };

  const createSupportTicket = async (returnData) => {

    const sendToEmail = [localStorage.getItem('userEmail')]
    const supportCategory = 'Order Dispute';
    let supportUrgency;
    if (returnData.urgency === '1 business day') {
      supportUrgency = 'High';
    } else if (returnData.urgency === '5+ business days') {
      supportUrgency = 'Low';
    } else {
      supportUrgency = 'Medium';
    }
    const ticket_increment = 5;
    const last_ticket_id = await fetchLastSupportTicketId();
    const new_ticket_id = last_ticket_id + ticket_increment;
    const subject = `Order dispute for ${orderDetails?.product_name}`;
    const locationName = orderDetails?.location_name;
    const origin = 'email';
    const description = `A ${returnOrReplace} request has been submitted for product ${orderDetails?.product_name}. The reason for the request is that the product is ${reason}.` + (additionalDetails ? ` Additional details provided: ${additionalDetails}.` : '');
    const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

    const supportData = {
        ticket_id: new_ticket_id,
        username: username,
        location_name: locationName,
        ticket_urgency: supportUrgency,
        tag: supportCategory,
        ticket_subject: subject,
        origin: origin,
        ticket_description: `${description}. The order ID of the disputed product is ${orderDetails?.order_id}.`,
    };
    await newSupportTicket(supportData);

    // Send a confirmation email
    const details = {
        user: user.name,
        tag: supportCategory,
        ticket_urgency: supportUrgency,
        ticket_subject: subject,
        ticket_description: description,
        ticket_id: new_ticket_id
    };

    const emailTemplate = 'Support Ticket';

    const emailData = {
        recipient_emails: sendToEmail,
        sender_email: supportEmail, 
        cc_emails: [],
        subject: `${ subject }: Support Ticket ${ new_ticket_id }`,
        message: emailTemplates[emailTemplate](details)
    };

    const conversationInfo = await sendEmail(emailData);
    const supportUpdateData = {conversation_id: conversationInfo.conversation_id, email_id: conversationInfo.email_id, };
    
    // Update with the new email
    await updateSupportTicketConversation(supportUpdateData, new_ticket_id);  
  };

  const handleReturn = () => {
    if (!additionalDetails && !urgency) {
      return;
    }
    const returnData = {
      reason,
      returnMethod: returnOrReplace,
      additionalDetails,
      urgency,
    };
    submitReturn(returnData);
    createSupportTicket(returnData);
    toast.success('Dispute request submitted successfully');

    setReason('');
    setUrgency('');
    setReturnOrReplace('');
    setAdditionalDetails('');
    setIsCheckboxChecked('');
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div style={{ height: '80%', overflowY: 'auto', padding: '0.5rem' }}>
            <p style={{ marginBottom: '3rem' }}>
              Medvelle’s return policy aligns with the policies set by our suppliers. We will make every effort to assist
              you throughout the return process, adhering to the guidelines and conditions established by the respective supplier. Please note that
              the final decision regarding returns is subject to the supplier’s approval and terms.
            </p>
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                style={{ marginLeft: '39%', marginRight: '-1%' }}
              />
              I understand
            </label>
          </div>
        );
      case 2:
        return (
          <div>
            <h3>Select a Return Reason:</h3>
            <select
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              style={{ padding: '0.5rem', width: '100%' }}
            >
              <option value="">Select Reason</option>
              <option value="Damaged">Damaged</option>
              <option value="Defective">Defective</option>
              <option value="Incorrect Item">Incorrect Item</option>
              <option value="Not Needed">Not Needed</option>
            </select>
          </div>
        );
      case 3:
        return (
          <div>
            <h3>Return or Replace:</h3>
            <select
              value={returnOrReplace}
              onChange={(e) => setReturnOrReplace(e.target.value)}
              style={{ padding: '0.5rem', width: '100%' }}
            >
              <option value="">Select Option</option>
              <option value="Return">Return</option>
              <option value="Replace">Replace</option>
            </select>
          </div>
        );
      case 4:
        return (
          <div>
            <h3 style={{ marginTop: '2rem' }}>Additional Details:</h3>
            <textarea
              value={additionalDetails}
              onChange={(e) => setAdditionalDetails(e.target.value)}
              style={{ width: '100%', height: '5rem', padding: '0.5rem' }}
              placeholder="Enter additional details here..."
            />
            <h3>Return Urgency:</h3>
            <select
              value={urgency}
              onChange={(e) => setUrgency(e.target.value)}
              style={{ padding: '0.5rem', width: '100%', marginBottom: '1rem' }}
            >
              <option value="">Select Option</option>
              <option value="1 business day">Urgent Request</option>
              <option value="2-3 business days">Quick Turnaround</option>
              <option value="3-5 business days">Standard Timeline</option>
              <option value="5+ business days">Flexible Schedule</option>
            </select>
          </div>
        );
      case 5:
        return (
          <div>
            <h3>What Medvelle Will Do Next:</h3>
            <ol style={{ textAlign: 'left' }}>
              <li>Review your return request.</li>
              <li>Contact the supplier for approval.</li>
              <li>Arrange for the return shipping label.</li>
              <li>Process the refund or replacement.</li>
              <li>Send you confirmation once complete.</li>
            </ol>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      overlayClassName="white-modal-overlay"
      className="white-modal"
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      style={{
        content: {
          width: '75vw',
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '0.15rem solid #87D3D7',
        },
      }}
    >
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <div>
          <h1 style={{ color: '#87D3D7', marginBottom: '-0.5rem', textAlign: 'center' }}>
            Medvelle Dispute Resolution Assistant
          </h1>
          <button
            className="support-button"
            style={{
              marginLeft: 'auto',
              fontSize: '1rem',
              display: 'flex',
              marginTop: '-2rem',
              marginRight: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
              height: '2.5rem',
              width: '2.5rem'
            }}
            onClick={handleModalClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <h3 style={{ color: 'green', marginBottom: '3vh', textAlign: 'center' }}>
          Helping you return {orderDetails?.product_name}!
        </h3>

        <ProgressBar steps={steps} currentStep={currentStep} totalSteps={steps.length} />

        <div
          style={{
            width: '40vw',
            height: '30vh',
            marginTop: '3vh',
            marginLeft: '20%',
            border: '0.15rem solid #87D3D7',
            borderRadius: '0.6rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2.5vw'
          }}
        >
          <div>{renderStepContent()}</div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-around', marginTop: '8px', paddingBottom: '10px'}}>
          <div style={{display: 'flex', gap: '1rem'}}>
            {(currentStep > 1 && currentStep < steps.length) && (
              <button
                onClick={handleBackStep}
                style={{
                  padding: '0.5rem 1rem',
                  backgroundColor: '#31bac1',
                  fontSize: '1rem',
                  color: 'white',
                  border: 'none',
                  borderRadius: '0.5rem',
                  cursor: 'pointer'
                }}
              >
                {"<"}
              </button>
            )}
          </div>

          <div style={{display: 'flex', gap: '1rem'}}>
            {currentStep < steps.length && (
              <button
                onClick={handleNextStep}
                style={{
                  padding: '0.5rem 1rem',
                  backgroundColor: '#31bac1',
                  fontSize: '1rem',
                  color: 'white',
                  border: 'none',
                  borderRadius: '0.5rem',
                  cursor: 'pointer'
                }}
              >
                {">"}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReturnModal;
