import { toast } from 'react-toastify';
import { fetchWithAuth } from '../authentication/authenticationUtils';

/**
 * Function to upload a file to the server.
 * @param {File} param0.file - The file to be uploaded.
 * @param {string} param0.fileName - The name of the file to be used on the server.
 */
export const postFile = async ({ file, fileName }) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("filename", "Bundles-invoices/" + fileName);

    const requestOptions = {
        method: 'POST',
        body: formData
    };

    try {
        const response = await fetchWithAuth(process.env.REACT_APP_BACKEND_URL + '/upload-files', requestOptions);
        if (!response.ok) {
            throw new Error('Failed to upload image');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error uploading image:', error);
        throw error; // Propagate the error further
    }
};

/**
 * Function to fetch file names from a specific folder in AWS.
 * @param {Function} setBundlesInvoices - A callback function to set the fetched file names in the state.
 */
export const fetchFiles = async () => {
    try {
        const response = await fetchWithAuth(process.env.REACT_APP_BACKEND_URL + '/get-aws-files', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ folder: "Bundles-invoices/" }), // Include the folder key in the request body as JSON
        });

        if (!response.ok) {
            throw new Error('Failed to fetch file names');
        }

        const data = await response.json();
        return data.files; // Update state with the fetched file names
    } catch (error) {
        console.error('Error fetching file names:', error.message);
        throw error; // Propagate the error further
    }
};

/**
 * Function to fetch a specific file from AWS and trigger a download.
 * @param {string} filename - The name of the file to be fetched.
 */
export const fetchFile = async (filename) => {
    try {
        const key = "Bundles-invoices/" + filename; // Construct the file key
        const response = await fetchWithAuth(process.env.REACT_APP_BACKEND_URL + '/get-file', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ key }), // Include the key in the request body as JSON
        });

        if (!response.ok) {
            toast.error("Failed to fetch file");
            throw new Error('Failed to fetch file');
        }

        toast.success("File fetched successfully");

        // Trigger download when fetch is successful
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${filename}.pdf`; // Set the download filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        console.log('File downloaded successfully');
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};


export const getOutstandingInvoices = async ({locationName, setOutstandingInvoicesAmount, signal = undefined}) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/outstanding-invoices/${ locationName }`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Failed to retrieve outstanding orders.');
        }

        const data = await response.json();
        setOutstandingInvoicesAmount(data?.[0]?.total);
        return data
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching outstanding orders:', error);
        }
        return []
    }
};