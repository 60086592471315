import React, { useEffect, useState, useRef } from "react";
import "../assets/style/index.css";
import axios from "axios";
import Modal from "react-modal";
import "react-datepicker/dist/react-datepicker.css";
import {
  formatDateLocationToUTCString,
  newDate,
  formatDateToYYYYMMDD,
} from "../utils/time_zones/TimeZones";
import SupportForm from "../features/support/SupportForm";
import loadingSpinner from "../assets/images/loading.gif"; // Import your loading spinner image
import {
  fetchWithAuth,
  axiosWithAuth,
} from "../utils/authentication/authenticationUtils";
import { toast, ToastContainer } from "react-toastify";

const Reporting = ({ locationName, call, permissionCode }) => {
  const contentRef = useRef();
  const [inventoryData, setInventoryData] = useState({});
  const [newOrdersData, setNewOrdersData] = useState({});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [ordersData, setOrdersData] = useState([]);
  const [manufacturerData, setManufacturerData] = useState([]);
  const [newManufacturerData, setNewManufacturerData] = useState({});
  const [viewDate, SetViewDate] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMonthModalOpen, setIsMonthModalOpen] = useState(false);
  const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [selectedAccountingReportType, setSelectedAccountingReportType] =
    useState(null);
  const [showContact, setShowContact] = useState(false);
  const [pricingStyle, setPricingStyle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const toggleContactInfo = () => {
    setShowContact(!showContact);
  };

  const today = newDate();
  const todayOneMonthAgo = newDate();
  todayOneMonthAgo.setMonth(todayOneMonthAgo.getMonth() - 1);

  const [selectedDate, setSelectedDate] = useState(formatDateToYYYYMMDD(today));
  const [selectedMonth, setSelectedMonth] = useState(
    formatDateToYYYYMMDD(todayOneMonthAgo).slice(0, 7)
  );
  const [startDate, setStartDate] = useState(formatDateToYYYYMMDD(today));
  const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(today));

  useEffect(() => {
    fetchData();

    contentRef.current.style.marginLeft = "250px";
    contentRef.current.style.marginRight = "50px";
  }, [locationName, pricingStyle]);

  useEffect(() => {
    filterData(viewDate);
  }, [viewDate, locationName]);

  const fetchData = async () => {
    try {
      const usernamePromise = fetchUsername();
      const pricingPromise = fetchWithAuth(`${call}/get-custom-pricing`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ location_name: locationName }),
      })
        .then((response) => response.json())
        .then((data) => setPricingStyle(data.pricingStyle));

      const inventoryPromise = fetchWithAuth(
        `${call}/inventory-dashboard?locationName=${locationName}&pricingStyle=${pricingStyle}`
      )
        .then((response) => response.json())
        .then((data) => processInventoryData(data));

      await Promise.all([usernamePromise, pricingPromise, inventoryPromise]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoadingPage(false);
    }
  };

  // Function to fetch the username
  const fetchUsername = () => {
    const userEmail = localStorage.getItem("userEmail");

    if (userEmail) {
      fetchWithAuth(`${call}/get-name?user_email=${userEmail}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setName(data.name);
          setUsername(data.username);
        })
        .catch((error) => console.error("Error fetching username:", error));
    }
  };

  // Function to process and filter orders data based on the selected date range
  const filterData = async (date) => {
    const currentDate = newDate();
    let startDate;
    let dateProcessed;

    // Calculate the startDate based on the selected range
    switch (date) {
      case "1":
        startDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
        break;
      case "3":
        startDate = new Date(currentDate.setDate(currentDate.getDate() - 90));
        break;
      case "6":
        startDate = new Date(currentDate.setDate(currentDate.getDate() - 180));
        break;
      case "12":
        startDate = new Date(currentDate.setDate(currentDate.getDate() - 360));
        break;
      default:
        startDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
    }

    dateProcessed = await formatDateLocationToUTCString(
      startDate,
      locationName
    );

    try {
      // Fetching data from orders-dashboard
      const ordersResponse = await fetchWithAuth(
        `${call}/orders-dashboard?locationName=${locationName}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newDate: dateProcessed }),
        }
      );

      if (!ordersResponse.ok) {
        throw new Error(`HTTP error! status: ${ordersResponse.status}`);
      }

      const ordersData = await ordersResponse.json();
      setOrdersData(ordersData);

      // Filter the orders data based on the date range
      const filteredOrdersData = ordersData.filter((order) => {
        return order.order_date >= dateProcessed;
      });

      processOrdersData(filteredOrdersData);

      // Fetching data from manufacturer-dashboard
      const manufacturerResponse = await fetchWithAuth(
        `${call}/manufacturer-dashboard?locationName=${locationName}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newDate: dateProcessed }),
        }
      );

      if (!manufacturerResponse.ok) {
        throw new Error(`HTTP error! status: ${manufacturerResponse.status}`);
      }

      const manufacturerData = await manufacturerResponse.json();
      setManufacturerData(manufacturerData);

      // Filter the manufacturer data based on the date range
      const filteredManufacturerData = manufacturerData.filter(
        (manufacturer) => {
          return manufacturer.order_date >= dateProcessed;
        }
      );

      processManufacturerData(filteredManufacturerData);
    } catch (error) {
      console.error("There was a problem fetching the data:", error);
    }
  };

  const processInventoryData = (data) => {
    const totals = {};

    data.forEach(({ product_cat, total_price }) => {
      if (!totals[product_cat]) {
        totals[product_cat] = 0;
      }
      totals[product_cat] += total_price;
    });

    const sortedTotals = Object.entries(totals).sort((a, b) => b[1] - a[1]);
    const sortedTotalsObject = Object.fromEntries(sortedTotals);

    setInventoryData(sortedTotalsObject);
  };

  const handleDateRangeChange = (event) => {
    SetViewDate(event.target.value);
    filterData(event.target.value);
  };

  const processOrdersData = (data) => {
    const totals = {};

    data.forEach(({ status_cat, total_price, order_count }) => {
      if (!totals[status_cat]) {
        totals[status_cat] = { totalPrice: 0, totalQuantity: 0 };
      }
      totals[status_cat].totalPrice += total_price;
      totals[status_cat].totalQuantity += order_count;
    });

    setNewOrdersData(totals);
  };

  const processManufacturerData = (data) => {
    const totals = {};

    data.forEach(
      ({ manufacturer_name, number_of_orders, total_order_value }) => {
        if (!totals[manufacturer_name]) {
          totals[manufacturer_name] = { totalOrders: 0, totalPrice: 0 };
        }
        totals[manufacturer_name].totalOrders += number_of_orders;
        totals[manufacturer_name].totalPrice += total_order_value;
      }
    );

    const sortedTotals = Object.entries(totals).sort(
      (a, b) => b[1].totalPrice - a[1].totalPrice
    );
    const sortedTotalsObject = Object.fromEntries(sortedTotals);

    setNewManufacturerData(sortedTotalsObject);
  };

  const handleInventoryReportDownload = async () => {
    setIsLoading(true);

    // Extracting year, month, and day from the selectedDate string ('yyyy-mm-dd')
    const [year, month, day] = selectedDate.split("-");

    // Formatting the date as 'month_day_year'
    const formattedDate = `${month}_${day}_${year}`;

    const selectedDateTime = new Date(year, month - 1, day);

    const today = newDate();
    today.setHours(0, 0, 0, 0);

    if (selectedDateTime > today) {
      alert("Please select a date previous to today's date.");
      setIsLoading(false);
      return;
    }

    const twoMonthsAgo = new Date(today);
    twoMonthsAgo.setMonth(today.getMonth() - 2);
    twoMonthsAgo.setDate(today.getDate() + 1);

    if (selectedDateTime <= twoMonthsAgo) {
      alert("Please select a date within the last 60 days.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axiosWithAuth.get(
        `${call}/download-inventory-report?locationName=${locationName}&username=${username}&name=${name}&date=${formattedDate}&pricingStyle=${pricingStyle}&today=${today}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `inventory_report_${locationName}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      alert(
        "Please note that reports can only be retrieved for dates within the last 60 days. For more information, contact Medvelle."
      );
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const handleDownloadWithDateRange = async () => {
    setIsLoading(true);
    const formattedStartDate = await formatDateLocationToUTCString(
      startDate + " 00:00:00",
      locationName
    );
    const formattedEndDate = await formatDateLocationToUTCString(
      endDate + " 23:59:59",
      locationName
    );

    try {
      const response = await axiosWithAuth.get(
        `${call}/download-${selectedReportType}?locationName=${locationName}&username=${username}&name=${name}`,
        {
          params: {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            pricingStyle: pricingStyle,
            isHistorical: 0,
          },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: response.headers["content-type"] });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${selectedReportType}_${locationName}.xlsx`);
      document.body.appendChild(link);
    
      // Ensures the file is fully processed before clicking
      setTimeout(() => {
        link.click();
      }, 500);
    
    } catch (error) {
      toast.error("Report is not available.");
      console.error("Error downloading report:", error);
    }
    setIsLoading(false);
    setIsDateRangeModalOpen(false);
  };

  const openDateRangeModalForReport = (reportType) => {
    setSelectedReportType(reportType);
    setIsDateRangeModalOpen(true);
  };

  const openDateRangeModalForAccountingReport = (reportType) => {
    setSelectedAccountingReportType(reportType);
    setIsMonthModalOpen(true);
  };

  const downloadAccountingReport = async () => {
    // Extract the month and year from the selectedMonth string (format 'yyyy-mm')
    const [year, month] = selectedMonth.split("-").map(Number);

    const reportDate = new Date(parseInt(year), parseInt(month), 6); // Report is available on the 6th of the next month
    const today = new Date();

    if (today < reportDate && permissionCode !== 11) {
      alert(
        "New accounting reports become available on the 6th day of each month, covering data from the previous month."
      );
      setIsLoading(false);
      return;
    }

    try {
      let params = {};

      if (selectedAccountingReportType !== "eom-report") {
        const startDateUsage = new Date(year, parseInt(month) - 1, 1);
        const endDateUsage = new Date(year, parseInt(month), 1);
        endDateUsage.setDate(endDateUsage.getDate() - 1); // Last day of the month

        const startUsageDateStr = await formatDateLocationToUTCString(
          startDateUsage.toISOString().split("T")[0] + " 00:00:00",
          locationName
        );
        const endUsageDateStr = await formatDateLocationToUTCString(
          endDateUsage.toISOString().split("T")[0] + " 23:59:59",
          locationName
        );

        params = {
          startDate: startUsageDateStr,
          endDate: endUsageDateStr,
          pricingStyle: pricingStyle,
          username: username,
          name: name,
          isHistorical: 1,
          month: month,
          year,
        };
      } else {
        params = {
          username,
          pricingStyle: pricingStyle,
          month,
          year,
        };
      }

      setIsLoading(true); // Start loading

      const response = await axiosWithAuth.get(
        `${call}/download-${selectedAccountingReportType}?locationName=${locationName}`,
        {
          params: params,
          responseType: "blob",
        }
      );

      // Check if the response is not an empty list
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${selectedAccountingReportType}_${locationName}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      setIsMonthModalOpen(false);
    } catch (error) {
      setIsMonthModalOpen(false);
      toast.error("Accounting report is not available.");
      console.error("Error downloading accounting report:", error);
    }

    setIsLoading(false); // Stop loading
    setIsMonthModalOpen(false);
  };

  return (
    <div ref={contentRef} style={{ textAlign: "left" }}>
      <div
        style={{
          textAlign: "left",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1
          style={{
            marginTop: "27px",
            fontSize: "40px",
            fontWeight: "bold",
            color: "#F49C7D",
          }}
        >
          Your Dashboard
        </h1>

        <button
          className="support-btn"
          onClick={toggleContactInfo}
          style={{
            backgroundColor: "#F49C7D",
            marginTop: "0px",
            marginRight: "-1px",
          }}
        >
          <h1 style={{ fontSize: "20px" }}>?</h1>
        </button>

        {showContact && (
          <SupportForm
            callBack={setShowContact}
            locationName={locationName}
            permissionCode={permissionCode}
          />
        )}
      </div>

      <label htmlFor="dateRange" style={{ marginRight: "10px" }}>
        Select Date Range:
      </label>
      <select
        onChange={handleDateRangeChange}
        id="dateRange"
        style={{ marginBottom: "20px", padding: "5px" }}
      >
        <option value="1">1 Month</option>
        <option value="3">3 Months</option>
        <option value="6">6 Months</option>
        <option value="12">12 Months</option>
      </select>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            border: "2px solid #F49C7D",
            display: "flex",
            justifyContent: "left",
            flexDirection: "column",
            width: "100%",
            maxHeight: "250px",
            overflowY: "auto",
            boxShadow: "0 30px 30px rgba(0, 0, 0, 0.1)",
          }}
        >
          <table
            style={{
              backgroundColor: "#ffffff",
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: 0,
              position: "relative",
            }}
          >
            <thead
              style={{
                position: "sticky",
                backgroundColor: "#ffffff",
                top: 0,
              }}
            >
              <tr>
                <th style={{ borderBottom: "2px solid black", padding: "5px" }}>
                  Product Category
                </th>
                <th
                  style={{
                    borderBottom: "2px solid black",
                    padding: "5px",
                    textAlign: "left",
                  }}
                >
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(inventoryData).map(([category, totalValue]) => (
                <tr key={category}>
                  <td style={{ padding: "5px", textAlign: "left" }}>
                    {category ? category : "Other"}
                  </td>
                  <td style={{ padding: "5px", textAlign: "left" }}>
                    ${Math.round(totalValue).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div
          style={{
            border: "2px solid #F49C7D",
            display: "flex",
            justifyContent: "left",
            marginLeft: "10px",
            flexDirection: "column",
            width: "100%",
            maxHeight: "250px",
            overflowY: "auto",
            boxShadow: "0 30px 30px rgba(0, 0, 0, 0.1)",
          }}
        >
          <table
            style={{
              backgroundColor: "#ffffff",
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: 0,
              position: "relative",
            }}
          >
            <thead
              style={{
                position: "sticky",
                backgroundColor: "#ffffff",
                top: 0,
              }}
            >
              <tr>
                <th style={{ borderBottom: "2px solid black", padding: "5px" }}>
                  Order Status
                </th>
                <th
                  style={{
                    borderBottom: "2px solid black",
                    padding: "5px",
                    textAlign: "left",
                  }}
                >
                  Quantity
                </th>
                <th
                  style={{
                    borderBottom: "2px solid black",
                    padding: "5px",
                    textAlign: "left",
                  }}
                >
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(newOrdersData).map(
                ([statusCat, { totalPrice, totalQuantity }]) => (
                  <tr key={statusCat}>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      {statusCat ? statusCat : "Other"}
                    </td>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      {totalQuantity}
                    </td>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      ${Math.round(totalPrice).toLocaleString()}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>

        <div
          style={{
            border: "2px solid #F49C7D",
            marginLeft: "10px",
            display: "flex",
            justifyContent: "left",
            flexDirection: "column",
            width: "100%",
            maxHeight: "250px",
            overflowY: "auto",
            boxShadow: "0 30px 30px rgba(0, 0, 0, 0.1)",
          }}
        >
          <table
            style={{
              backgroundColor: "#ffffff",
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: 0,
              position: "relative",
            }}
          >
            <thead
              style={{
                position: "sticky",
                backgroundColor: "#ffffff",
                top: 0,
              }}
            >
              <tr>
                <th style={{ borderBottom: "2px solid black", padding: "5px" }}>
                  Manufacturer Name
                </th>
                <th
                  style={{
                    borderBottom: "2px solid black",
                    padding: "5px",
                    textAlign: "left",
                  }}
                >
                  Quantity
                </th>
                <th
                  style={{
                    borderBottom: "2px solid black",
                    padding: "5px",
                    textAlign: "left",
                  }}
                >
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(newManufacturerData).map(
                ([statusCat, { totalPrice, totalOrders }]) => (
                  <tr key={statusCat}>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      {statusCat ? statusCat : "Other"}
                    </td>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      {totalOrders}
                    </td>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      ${Math.round(totalPrice).toLocaleString()}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1
          style={{
            marginTop: "27px",
            fontSize: "40px",
            fontWeight: "bold",
            color: "#F49C7D",
          }}
        >
          Live Reports
        </h1>
      </div>

      {/* First row of forms */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flex: "1 1 30%", margin: "5px" }}>
          <button
            className="report-form"
            onClick={() => setIsModalOpen(true)}
            style={{
              width: "100%",
              display: "flex",
              alignItems: isLoadingPage ? "center" : "flex-start",
              justifyContent: isLoadingPage ? "center" : "flex-start",
            }}
            disabled={isLoadingPage}
          >
            {isLoadingPage ? (
              <img
                src={loadingSpinner}
                alt="Loading..."
                style={{ height: "20px" }}
              />
            ) : (
              <>
                <h2 style={{ textAlign: "center", width: "100%" }}>
                  Inventory Report
                </h2>
                <p>
                  Get a report detailing your current inventory, including
                  quantities and dollar values for each product.
                </p>
              </>
            )}
          </button>
        </div>
        <div style={{ flex: "1 1 30%", margin: "5px" }}>
          <button
            className="report-form"
            onClick={() => openDateRangeModalForReport("orders-report")}
            style={{
              width: "100%",
              display: "flex",
              alignItems: isLoadingPage ? "center" : "flex-start",
              justifyContent: isLoadingPage ? "center" : "flex-start",
            }}
            disabled={isLoadingPage}
          >
            {isLoadingPage ? (
              <img
                src={loadingSpinner}
                alt="Loading..."
                style={{ height: "20px" }}
              />
            ) : (
              <>
                <h2 style={{ textAlign: "center", width: "100%" }}>
                  Order Report
                </h2>
                <p>
                  Get a report outlining all past purchases, itemized by
                  product. This report can be filtered by date, vendor, and
                  order code, and includes details such as unit price, taxes,
                  and total cost.
                </p>
              </>
            )}
          </button>
        </div>
        <div style={{ flex: "1 1 30%", margin: "5px" }}>
          <button
            className="report-form"
            onClick={() => openDateRangeModalForReport("product-usage-report")}
            style={{
              width: "100%",
              display: "flex",
              alignItems: isLoadingPage ? "center" : "flex-start",
              justifyContent: isLoadingPage ? "center" : "flex-start",
            }}
            disabled={isLoadingPage}
          >
            {isLoadingPage ? (
              <img
                src={loadingSpinner}
                alt="Loading..."
                style={{ height: "20px" }}
              />
            ) : (
              <>
                <h2 style={{ textAlign: "center", width: "100%" }}>
                  Product Usage Report
                </h2>
                <p>
                  Get a report that shows predicted product consumption by
                  category over a custom time frame.
                </p>
              </>
            )}
          </button>
        </div>
      </div>

      {/* Second row of forms */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flex: "1 1 30%", margin: "5px" }}>
          <button
            className="report-form"
            onClick={() => openDateRangeModalForReport("accrual-cogs-report")}
            style={{
              width: "100%",
              display: "flex",
              alignItems: isLoadingPage ? "center" : "flex-start",
              justifyContent: isLoadingPage ? "center" : "flex-start",
            }}
            disabled={isLoadingPage}
          >
            {isLoadingPage ? (
              <img
                src={loadingSpinner}
                alt="Loading..."
                style={{ height: "20px" }}
              />
            ) : (
              <>
                <h2 style={{ textAlign: "center", width: "100%" }}>
                  Accrual COGS Report
                </h2>
                <p>
                  Get a report that provides the estimated accrued COGS by
                  Service for your location.
                </p>
              </>
            )}
          </button>
        </div>
        <div style={{ flex: "1 1 30%", margin: "5px" }}>
          <button
            className="report-form"
            onClick={() => openDateRangeModalForReport("treatment-report")}
            style={{
              width: "100%",
              display: "flex",
              alignItems: isLoadingPage ? "center" : "flex-start",
              justifyContent: isLoadingPage ? "center" : "flex-start",
            }}
            disabled={isLoadingPage}
          >
            {isLoadingPage ? (
              <img
                src={loadingSpinner}
                alt="Loading..."
                style={{ height: "20px" }}
              />
            ) : (
              <>
                <h2 style={{ textAlign: "center", width: "100%" }}>
                  Treatment Report
                </h2>
                <p>
                  Get a report that shows the usage and margins of services at
                  your location. This report allows for price adjustments to
                  facilitate margin testing of promotions or price changes.
                </p>
              </>
            )}
          </button>
        </div>
        <div style={{ flex: "1 1 30%", margin: "5px" }}>
          <button
            className="report-form"
            onClick={() => openDateRangeModalForReport("retail-report")}
            style={{
              width: "100%",
              display: "flex",
              alignItems: isLoadingPage ? "center" : "flex-start",
              justifyContent: isLoadingPage ? "center" : "flex-start",
            }}
            disabled={isLoadingPage}
          >
            {isLoadingPage ? (
              <img
                src={loadingSpinner}
                alt="Loading..."
                style={{ height: "20px" }}
              />
            ) : (
              <>
                <h2 style={{ textAlign: "center", width: "100%" }}>
                  Retail Report
                </h2>
                <p>
                  Get a report that shows the usage and margins of retail sales
                  at your location. This report allows for price adjustments to
                  facilitate margin testing of promotions or price changes.
                </p>
              </>
            )}
          </button>
        </div>
      </div>

      <h1 style={{ fontSize: "40px", fontWeight: "bold", color: "#F49C7D" }}>
        Accounting Reports
      </h1>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div style={{ flex: "1 1 30%", margin: "5px" }}>
          <button
            className="future-report-form"
            onClick={() =>
              openDateRangeModalForAccountingReport("product-usage-report")
            }
            style={{
              width: "100%",
              display: "flex",
              alignItems: isLoadingPage ? "center" : "flex-start",
              justifyContent: isLoadingPage ? "center" : "flex-start",
            }}
            disabled={isLoadingPage}
          >
            {isLoadingPage ? (
              <img
                src={loadingSpinner}
                alt="Loading..."
                style={{ height: "20px" }}
              />
            ) : (
              <>
                <h2 style={{ textAlign: "center", width: "100%" }}>
                  Product Usage Report
                </h2>
                <p>
                  Get a historical report that shows predicted product
                  consumption by category over a month.
                </p>
              </>
            )}
          </button>
        </div>
        <div style={{ flex: "1 1 30%", margin: "5px" }}>
          <button
            className="future-report-form"
            onClick={() =>
              openDateRangeModalForAccountingReport("accrual-cogs-report")
            }
            style={{
              width: "100%",
              display: "flex",
              alignItems: isLoadingPage ? "center" : "flex-start",
              justifyContent: isLoadingPage ? "center" : "flex-start",
            }}
            disabled={isLoadingPage}
          >
            {isLoadingPage ? (
              <img
                src={loadingSpinner}
                alt="Loading..."
                style={{ height: "20px" }}
              />
            ) : (
              <>
                <h2 style={{ textAlign: "center", width: "100%" }}>
                  Accrual COGS Report
                </h2>
                <p>
                  Get a historical report that provides the estimated accrued
                  COGS by Service for your location. This report can be utilized
                  for monthly accounting purposes.
                </p>
              </>
            )}
          </button>
        </div>
        <div style={{ flex: "1 1 30%", margin: "5px" }}>
          <button
            className="future-report-form"
            onClick={() => openDateRangeModalForAccountingReport("eom-report")}
            style={{
              width: "100%",
              display: "flex",
              alignItems: isLoadingPage ? "center" : "flex-start",
              justifyContent: isLoadingPage ? "center" : "flex-start",
            }}
            disabled={isLoadingPage}
          >
            {isLoadingPage ? (
              <img
                src={loadingSpinner}
                alt="Loading..."
                style={{ height: "20px" }}
              />
            ) : (
              <>
                <h2 style={{ textAlign: "center", width: "100%" }}>
                  End of Month Report
                </h2>
                <p>
                  Get a comprehensive End-of-Month Report that delivers a
                  detailed overview of your product movements throughout the
                  month including starting inventory, product usage, orders,
                  shrinkage, and ending inventory value.
                </p>
              </>
            )}
          </button>
        </div>
      </div>

      <Modal
        isOpen={isMonthModalOpen}
        onRequestClose={() => setIsMonthModalOpen(false)}
        style={{
          content: {
            width: "320px",
            backgroundColor: "#87d3d7",
            height: "300px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "5%",
          },
        }}
      >
        <h2>Select Month</h2>
        <input
          type="month"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
          placeholder="Select month and year"
        />
        <p></p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={downloadAccountingReport}
            style={{
              border: "3px solid #31bac1",
              backgroundColor: "#31bac1",
              padding: "5px",
              borderRadius: "3%",
            }}
          >
            Download Report
          </button>

          {isLoading && (
            <img
              src={loadingSpinner}
              alt="loading-spinner"
              style={{
                height: "20px",
                marginLeft: "10px",
              }}
            />
          )}
        </div>
      </Modal>

      <Modal
        isOpen={isDateRangeModalOpen}
        onRequestClose={() => setIsDateRangeModalOpen(false)}
        style={{
          content: {
            width: "320px",
            backgroundColor: "#87d3d7",
            height: "50%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "5%",
          },
        }}
      >
        <h2>Select Date Range</h2>
        <div style={{ marginLeft: "20px" }}>
          <div>
            <p>Start Date:</p>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div>
            <p>End Date:</p>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <p></p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              onClick={handleDownloadWithDateRange}
              style={{
                border: "3px solid #31bac1",
                backgroundColor: "#31bac1",
                padding: "5px",
              }}
            >
              Download Report
            </button>

            {isLoading && (
              <img
                src={loadingSpinner}
                alt="loading-spinner"
                style={{
                  height: "20px",
                  marginLeft: "10px",
                }}
              />
            )}
          </div>
        </div>
      </Modal>

      <Modal
        className="modal-content"
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          content: {
            width: "320px",
            height: "40%",
          },
        }}
      >
        <h2>Select Date</h2>
        <p>
          Generated report will fetch the inventory report for the start of
          selected date.
        </p>
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
        />
        <p></p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={handleInventoryReportDownload}
            style={{
              border: "3px solid #31bac1",
              backgroundColor: "#31bac1",
              padding: "5px",
            }}
          >
            Download Report
          </button>

          {isLoading && (
            <img
              src={loadingSpinner}
              alt="loading-spinner"
              style={{
                height: "20px",
                marginLeft: "10px",
              }}
            />
          )}
        </div>
        <p></p>
        <button
          onClick={() => setIsModalOpen(false)}
          style={{
            border: "3px solid #31bac1",
            backgroundColor: "#31bac1",
            padding: "5px",
          }}
        >
          Close
        </button>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Reporting;
