import React, { useEffect, useState, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import ConfirmationModal from '../components/ConfirmationModal';
import { updateDiscontinueProduct, handleRemove, fetchInventoryData, fetchLocations, fetchLocationOrdersBlocked } from '../utils/locations/locationsUtils';
import { sortArrayByAttribute, handleSortChange, getArrow } from '../utils/tables/sortUtils';
import {getProductClass, addToInventory, handleEditQuantity} from '../utils/inventory/inventoryManagement';
import {checkCurrentOrders} from '../utils/orders/orderManagementUtils'
import SupportForm from '../features/support/SupportForm'
import AdvancedSearchModal from '../features/inventory/AdvancedSearchModal';
import OwnerApprovalModal from '../features/inventory/OwnerApprovalModal';
import InventoryReportModal from '../features/reports/InventoryReportModal';

import UnitConversionDisplay from '../features/inventory/UnitConversionDisplay'
import OrderModal from '../components/OrderModal';
import ModalHeader from '../components/ModalHeader';
import { fuzzySearch } from '../utils/products/search';
import {fetchUserInfo} from '../utils/users/userUtils';
import { getLiveRecommendations } from '../utils/orders/orderInfoUtils';
import {createAbortController, getAbortSignal, closeAbortController} from '../utils/controller/abortUtils';
import {fetchInventoryQuantity} from '../utils/inventory/inventoryInfo';
import { fetchWithAuth } from '../utils/authentication/authenticationUtils';

const Inventory = ({ locationName, call, permissionCode }) => {
  const contentRef = useRef();
  const abortControllerRef = useRef(null);  // Create a ref to hold the AbortController

  const [inventoryData, setInventoryData] = useState([]);
  const [editOption, setEditOption] = useState('Discontinue');
  const [username, setUsername] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // State to manage searched item
  const [selectedCategory, setSelectedCategory] = useState(''); // State to manage selected category
  const [orderModalIsOpen, setOrderModalIsOpen] = useState(false); // State to handle the popup that appears when place order is clicked
  const [editQuantityModalIsOpen, setEditQuantityModalIsOpen] = useState(false); // State to handle the popup that appears when place order is clicked
  const [inventoryReportModalOpen, setInventoryReportModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null); // State to the selected product when placing an order
  const [editQuantityNumber, setEditQuantityNumber] = useState(1); // State to handle the inventory quantity changes
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [locations, setLocations] = useState([]); // Updated to store locations data
  const [selectedDestination, setSelectedDestination] = useState('');
  const [orderThreshold, setOrderThreshold ] = useState([]);
  const [isDiscontinueRemovePopupVisible, setIsDiscontinueRemovePopupVisible] = useState(false);
  const [modalSource, setModalSource] = useState('');
  const [isLoadingAdditionalRecommendations, setIsLoadingAdditionalRecommendations] = useState(false); // State variable to track loading

  const [queryResult, setQueryResult] = useState([]);
  const [origin, setOrigin] = useState('');
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

  const [sortAttribute, setSortAttribute] = useState('');
  const [sortAsc, setSortAsc] = useState(true);

  const [isAdvancedModalOpen, setIsAdvancedModalOpen] = useState(false);
  const [filteredInventory, setFilteredInventory] = useState([])
  const [modalTab, setModalTab] = useState('Edit Inventory');
  const [showContact, setShowContact] = useState(false);
  const [showownerApproval, setShowOwnerApproval] = useState(false);
  const [isLocationBlocked, setIsLocationBlocked] = useState(false);

  useEffect(() => {
    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '50px';
  }, []);

  useEffect(()=>{
    createAbortController(abortControllerRef);
    const signal = getAbortSignal(abortControllerRef);

    fetchLocationOrdersBlocked(locationName, setIsLocationBlocked)
    // Fetch inventory data from the server
    fetchInventoryData(locationName, setInventoryData, true, signal);

    fetchUserInfo({locationName, callBack: (data) => {
      setUsername(data.username);
      setOrderThreshold(data.order_approval_limit);
    }, signal});

    fetchLocations(setLocations, signal);
    return () => {
      closeAbortController(abortControllerRef)
    };
  },[locationName])

  useEffect(() => {
    const locationInventoryData = [...inventoryData]

    setFilteredInventory(sortArrayByAttribute(
      fuzzySearch([...locationInventoryData].map(item => ({...item, product_full: `${ item.product_name } ${ item.product_description }`})),
        searchTerm, ['product_name', 'product_description', 'manufacturer_name', 'product_cat', 'product_full'], .2)
        .filter(item =>
          (selectedCategory === '' || item.product_cat.toLowerCase() === selectedCategory.toLowerCase()) &&
          (selectedManufacturer === '' || item.manufacturer_name.toLowerCase() === selectedManufacturer.toLowerCase())
        ), sortAttribute, sortAsc));
  }, [sortAttribute, sortAsc, searchTerm, inventoryData, selectedManufacturer, selectedCategory])

  const locationInventoryData = [...inventoryData]

  filteredInventory.forEach(item => {
    item.quantity = item.quantity_conversion !== 0 ? parseFloat((item.inventory_quantity / item.quantity_conversion)).toFixed(1) : item.inventory_quantity; // Add new field valueC to each object
    item.edit = <button onClick={() => {
      const subunit = (item.product_tier === 1 && (item.quantity_conversion <= 50 && item.quantity_conversion > 1)) ? item.product_subunit : null

      handleEditQuantityModal(locationName, item.product_id, item.product_name, item.inventory_quantity, item.quantity_conversion, item.discontinue, item.product_unit, item.product_description, subunit, item.product_tier, item)
    }}
                  style={{backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px'}}>
                  Edit
              </button>; 
    item.order = <button onClick={() => handleOrder(item.product_name, item.product_id, 'inventory', item.manufacturer_name, item.product_unit, item.product_description, item)} style={{backgroundColor: '#87D3D7', color: '#fff', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px'}}>
      {permissionCode === 25 || permissionCode === 26 || ((permissionCode === 22 || permissionCode === 27) && orderThreshold === 0) ? 'Request' : 'Order'}
    </button>;
  });
  

  // Gets unique category of products
  const uniqueCategories = Array.from(new Set(locationInventoryData.map(item => item.product_cat)));

  const uniqueManufacturers = Array.from(new Set(locationInventoryData.map(item => item.manufacturer_name)));

 // This handles the logic of the popup when the edit button is clicked
  const handleEditQuantityModal = async (locationName, productId, title, currentQuantity, quantityConversion, discontinue, productUnit, productDescription, productSubunit, productTier, item) => {
    setEditQuantityModalIsOpen(true);
    setEditQuantityNumber((currentQuantity && quantityConversion) && parseFloat(parseFloat(currentQuantity / quantityConversion)));
    // Set selected product details
    setSelectedProduct({title, productId, locationName, currentQuantity, quantityConversion, discontinue, productUnit, productDescription, productSubunit, productTier, item});
  };

const submitEditQuantity = async (editOption) => {

  if (editQuantityNumber == null || isNaN(editQuantityNumber)){
    alert("Please enter valid number.");
    return;
  }

  if (!selectedProduct) {
    alert("No product selected.");
    return;
  }

  if (!editOption) {
    alert("Please select an option.");
    return;
  }

  const newQuantity = editQuantityNumber;
  const newQuantityConversion = selectedProduct.quantityConversion

  switch (editOption) {
    case 'edit':
      // Call API to edit quantity
      const editSuccess = await handleEditQuantity({locationName, productId: selectedProduct.productId, currentQuantity: selectedProduct.currentQuantity, quantity_conversion_rate: newQuantityConversion, newQuantity, origin: 'Edit Inventory Open', username});
      if (editSuccess) {
        toast.success(`Quantity for ${ selectedProduct.title } updated successfully!`);
        fetchInventoryData(locationName, setInventoryData, true, getAbortSignal(abortControllerRef));
      }
      break;
    case 'move':
      // Call API to move quantity
      moveQuantity(locationName, selectedProduct.productId, selectedProduct.title, selectedProduct.currentQuantity, newQuantityConversion, newQuantity);
      break;
    case 'add':
      // Call API to add free quantity
      addFreeQuantity(selectedProduct.productId, selectedProduct.title, locationName, selectedProduct.currentQuantity, newQuantity, newQuantityConversion);
      break;
    default:
      console.error("Invalid option selected.");
  }

  // Close modal or perform any other necessary actions
  resetEditModal();
  
  setEditQuantityModalIsOpen(false);
};


const moveQuantity = async (locationName, productId, productName, currentQuantity, quantityConversion, newQuantity) => {

  if (selectedDestination == ''){
    alert(`No location selected.`);
    return;
  }
  const quantityOtherLocation = await fetchInventoryQuantity({locationName: selectedDestination, productId})

  if (quantityOtherLocation === false) {
    alert(`The location ${ selectedDestination } has not registered this product '${ productName }' into the system yet. Please contact Medvelle.`);
    return;
  }

  if (newQuantity > currentQuantity / quantityConversion){
    alert("The moving quantity cannot be bigger than your current inventory quantity.");
    return;
  }

  // Remove quantity to original location
  const localEditSuccess = handleEditQuantity({locationName, productId, currentQuantity, quantity_conversion_rate: quantityConversion, newQuantity: (currentQuantity / quantityConversion - newQuantity), origin: `Move (-) to ${selectedDestination}`, username});
  // ({locationName, productId, currentQuantity, quantity_conversion_rate, newQuantity, origin, username})
  if (localEditSuccess) {
    // Add quantity to new location
    fetchInventoryData(locationName, setInventoryData, true);

    handleEditQuantity({locationName: selectedDestination, productId, currentQuantity: quantityOtherLocation, quantity_conversion_rate: quantityConversion, newQuantity: quantityOtherLocation / quantityConversion + newQuantity, origin: `Move (+) from ${locationName}`, username});
    
    toast.success('Quantity moved successfully!');
  }  
};

// Edit inventory function
const addFreeQuantity = async (productId, productName, locationName, currentQuantity, newQuantity, quantityConversion) => {
  // Add quantity to inventory

  if (newQuantity === 0){
    alert("Please enter a quantity greater than zero.");
    return;
  }

  const freeQuantSuccess = await handleEditQuantity({locationName, productId, currentQuantity, quantity_conversion_rate: quantityConversion, newQuantity: (currentQuantity / quantityConversion + newQuantity), origin: 'Order (+)', username});

  if (freeQuantSuccess) {
    fetchInventoryData(locationName, setInventoryData, true);
  }
  // Define the order data to be sent in the request body
  const orderData = {
    order_code: -2,
    location_name: locationName,
    product_id: productId,
    order_quantity: newQuantity,
    unit_price: 0,
    vat_percent: 0,
    batch_expiration: null,
    internal_description: 'Free product',
    client_comment: '',
    status_cat: 'Delivered',
    status_rationale: '',
    recommendation_rationale: '',
    due_date: null,
    payment_date: null,
    order_comment: username,
    recommendation_quantity: null,
    order_urgency: '',
    bundle_date: ''
  };

  let lastOrderId = -1;

  // Make a POST request to the server endpoint to add a new order
  const response = await fetchWithAuth(`${call}/add-order`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(orderData)
  })
  .then(response => {
    // Check if the response is successful (status code 201)
    if (response.status === 201) {
      // Parse the JSON response

      toast.success('Added free products successfully!');

      return response.json();
    } else {
      // If response is not successful, throw an error
      throw new Error('Failed to add new order');
    }
  })
  .then(data => {
    // Extract the newly added order ID from the response data
    lastOrderId = data.order_id;

    // Do something with the newly added order ID
  })
  .catch(error => {
    // Handle any errors that occurred during the request
    console.error('Error:', error.message);
  });

  if (lastOrderId != -1){

    // Create order tracking
    await fetchWithAuth(`${call}/store-orders-tracking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        order_id: lastOrderId,
        location_name: locationName,
        product_id: productId,
        order_quantity_before: newQuantity, // Get old order quantity from fetched old data
        order_quantity_after: newQuantity, // Get new order quantity from editedOrder
        unit_price_before: 0, // Get old unit price from fetched old data
        unit_price_after: 0, // Get new unit price from editedOrder
        order_comment_before: '', // Get old internal description from fetched old data
        order_comment_after: username, // Get new internal description from editedOrder
        status_cat_before: 'Free product',
        status_cat_after: 'Delivered',
        change_datetime: '', // Handle in server.js
      }),
    })
    .then(response => response.json())
    .then(result => {
      if (result.message) {
        console.log('Order tracking data inserted successfully');
      } else {
        console.error('Failed to insert order tracking data');
      }
    })
    .catch(error => console.error('Error inserting order tracking data:', error));
  
  }
  

};

  // This handles the logic of the popup when the order button is clicked
  const handleOrder = async (title, productId, origin, manufacturer_name, productUnit, productDescription, item) => {
    setOrderModalIsOpen(true);
    setOrigin(origin);
    if (origin === 'web') {
      setSelectedProduct({title, item: {product_name: title}})
      setQueryResult(manufacturer_name); //actually result data from web searched product

    }
    else{
      setSelectedProduct({title, productId, manufacturer_name, productUnit, productDescription, item});
      setQueryResult(manufacturer_name); //actually result data from web searched product
    }
    // Set selected product details
    
  };

  // Handles the logic when the order is submitted from the popup
  const submitOrder = async (orderQuantity, orderUrgency, orderComment) => {
    if (orderQuantity <= 0) {
      toast.error('Please input an order quantity.');
      return;
    }
    if(origin === 'web'){
      const response =  await fetchWithAuth(`${call}/accept-order-from-web-search/${queryResult}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({orderQuantity: orderQuantity, orderComment, locationName, title: queryResult.title, price: queryResult.extracted_price, username, orderUrgency, permissionCode, internalDescription: queryResult.product_link}),
      });

      if(permissionCode !== 22 && permissionCode !== 27){
        toast.success("Order accepted by system for processing");
      }

      
      const order_price = queryResult.extracted_price*orderQuantity;

      if(permissionCode === 22 || permissionCode === 27){
        console.log('processing for manager');
        if(order_price>=orderThreshold){
          setShowOwnerApproval(true);
        }
        else{
          toast.success("Order accepted by system for processing");  
        }
      }

    }

    else{
      // Add product to inventory first.
      if (!inventoryData.some(item => ((item.location_name === locationName) && (item.product_id === selectedProduct.productId)))) {
        const productClassAvg = await getProductClass(selectedProduct.productId);
        const result = await addToInventory(selectedProduct.productId, locationName, 0, productClassAvg, username);
        if (!result) {
          toast.error('There was an issue processing your request.');
          // Close the order modal
          setOrderModalIsOpen(false);
          // Reset order details
          setSelectedProduct(null);
          return;
        }
      }


      const priceResponse = await fetchWithAuth(`${call}/get-price-product`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({product_id: selectedProduct.productId, location_name: locationName }),
      });

      const data = await priceResponse.json();

      const order_price = data.priceInfo.client_price*orderQuantity;
  

      const submitOrderBoolean = await checkCurrentOrders(locationName, selectedProduct.productId);

      if (submitOrderBoolean){
        const response = await fetchWithAuth(`${call}/accept-order-from-inventory/${selectedProduct.productId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ orderQuantity, locationName, orderComment, username, orderUrgency, manufacturer_name: selectedProduct.manufacturer_name, permissionCode }),
        });

        if (permissionCode > 22 && permissionCode !== 27){
          setOrderModalIsOpen(false);
          toast.success("Order accepted by system for processing");          
        }

        if(permissionCode === 22 || permissionCode === 27){
          console.log('processing for manager');
          if(order_price>=orderThreshold){
            setShowOwnerApproval(true);
          }
          else{
            toast.success("Order accepted by system for processing");  
          }
        }
        

        setIsLoadingAdditionalRecommendations(true);
        
        const recommendations = await getLiveRecommendations(locationName, selectedProduct.productId);

        if (Object.keys(recommendations.data).length > 0 && (permissionCode <= 22 || permissionCode === 27)){
          alert("Medvelle AI has added additional recommendations to complement this order. Please review in your Home Page.");
        }

        setIsLoadingAdditionalRecommendations(false);

        if (permissionCode < 22){
          toast.success("Order accepted by system for processing");          
        }

      }

    }

    // Close the order modal
    setOrderModalIsOpen(false);
    // Reset order details
    setSelectedProduct(null);
    // Close the advancedsearch modal
    if (isAdvancedModalOpen) {
      setIsAdvancedModalOpen(false);
      fetchInventoryData(locationName, setInventoryData, true);
    } 
  };


  const columnWidths = {
    name: "30%", 
    description: "30%",
    manufacturer: "10%",
    category: "10%",
    quantity: "10%",
    edit: "5%",
    order: "5%"
  };

  const toggleContactInfo = () => {
    setShowContact(!showContact);
  }

  
  function resetEditModal(){
    setEditQuantityModalIsOpen(false);
    setEditQuantityNumber(1);
    setSelectedDestination('');
    setModalTab('Edit Inventory');
  };

  const toggleDiscontinueRemovePopUp = () => {
    setIsDiscontinueRemovePopupVisible(!isDiscontinueRemovePopupVisible);
  };

  return (

    <div ref={contentRef} style={{ textAlign: 'left'}}>

    <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        
      <h1 style={{marginTop: '27px',  display: 'flex', fontSize: '40px', fontWeight: 'bold', color: '#87D3D7' }}>Your Inventory</h1>



      <button className="support-btn" onClick={toggleContactInfo} style={{ backgroundColor: '#87D3D7', marginRight: '-4px'}}>
        <h1 style={{ fontSize: '20px' }}>?</h1>
      </button>
      {showContact && (
        <SupportForm callBack={setShowContact} locationName={locationName} permissionCode={permissionCode} />
      )}

      </div>

      <div style={{display: 'flex', justifyContent: 'left', marginBottom: '20px', marginTop: '24px'}}>


        <input
          className='select-box'
          type="text"
          placeholder="Search Product Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}

          style={{marginTop: '-4px', padding: '10px', fontStyle: 'italic', width: '20vw', marginRight: '20px'}}
        />
        <select
          className='select-box'
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          style={{marginTop: '-4px', padding: '10px', fontStyle: 'italic', width: '15vw', marginRight: '20px'}}
        >
          <option value="">All Categories</option>
          {uniqueCategories.sort().map(product_cat => (
            <option key={product_cat} value={product_cat}>
              {product_cat}
            </option>
          ))}
        </select>

          
        <select
          className='select-box'
          value={selectedManufacturer}
          onChange={(e) => setSelectedManufacturer(e.target.value)}
          style={{marginTop: '-4px', padding: '10px', fontStyle: 'italic', width: '15vw', marginRight: '20px'}}
        >
          <option value="">All Manufacturers</option>
          {uniqueManufacturers.sort().map(manufacturer => (
            <option key={manufacturer} value={manufacturer}>
              {manufacturer}
            </option>
          ))}
        </select>
        
        { permissionCode !== 26 && 
          <button className='action-button'
            onClick={() => {
              setInventoryReportModalOpen(true);
            }}
            title="Download a sheet of your current Inventory"
          >
            {'Download'}
          </button>
        }

        <button
          className='action-button'
          style={{marginLeft: 'auto', fontSize: 'medium', }}
          onClick={() => {
            setModalSource('Add Product');
            setIsAdvancedModalOpen(true);
          }}>
          Add Product
        </button >

      </div> 

      {/* This is the inventory table. */}
      <div style={{ 
        border: '2px solid #87D3D7', 
        display: 'flex', 
        justifyContent: 'left', 
        flexDirection: 'column', 
        width: '100%', 
        maxHeight: '75vh', // Set a fixed height
        overflowY: 'auto', // Enable vertical scrolling
        boxShadow: '0 30px 30px rgba(0, 0, 0, 0.1)',
      }}>
        <table style={{  
          backgroundColor: '#ffffff', 
          width: '100%', 
          borderCollapse: 'separate', 
          borderSpacing: 0, 
          position: 'relative' // Ensure positioning context
        }}>
          <thead style={{
            position: 'sticky', top: 0
          }}>
            <tr style={{ fontSize: '13px', backgroundColor: '#fffff', color: '#000000' }}>
                <th onClick={() => handleSortChange('product_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.name, textAlign: 'left', backgroundColor: '#ffffff', cursor: 'pointer'}}>Product Name {getArrow('product_name', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('product_description', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.description, textAlign: 'left', backgroundColor: '#ffffff', cursor: 'pointer'}}>Description {getArrow('product_description', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('manufacturer_name', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.manufacturer, textAlign: 'center', backgroundColor: '#ffffff', cursor: 'pointer'}}>Manufacturer {getArrow('manufacturer_name', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('product_cat', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.category, textAlign: 'center', backgroundColor: '#ffffff', cursor: 'pointer'}}>Category {getArrow('product_cat', sortAttribute, sortAsc)}</th>
                <th onClick={() => handleSortChange('inventory_quantity', sortAttribute, setSortAttribute, sortAsc, setSortAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.quantity, textAlign: 'center', backgroundColor: '#ffffff', cursor: 'pointer'}}>Quantity {getArrow('inventory_quantity', sortAttribute, sortAsc)}</th>
                <th style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.edit, textAlign: 'center', backgroundColor: '#ffffff'}}>Edit</th>
                <th style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.order, textAlign: 'center', backgroundColor: '#ffffff'}}>Order</th>
            </tr>
          </thead>
          <tbody>
            {(filteredInventory.length === 0) ? (
              <tr>
                <td colSpan="7" style={{textAlign: 'center', verticalAlign: 'middle', fontSize: '16px', fontStyle: 'italic', padding: '50px 10px', height: '20vh'}}>
                  <button
                    className='support-button'
                    style={{fontSize: 'large', padding: '12px'}}
                    onClick={() => {
                      setModalSource('Advanced Search');
                      setIsAdvancedModalOpen(true);
                    }}>

                    Advanced Search
                  </button >
                </td>
              </tr>
            ) : (
              filteredInventory.map((item, index) => (
              <tr key={item.id} style={{ fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff' }} >
                <td style={{padding: '10px',}}>{item.product_name}</td>
                <td style={{padding: '10px', }}>{item.product_description}</td>
                <td style={{padding: '10px', textAlign: 'center'}}>{item.manufacturer_name}</td>
                <td style={{ padding: '10px' , textAlign: 'center' }}>{item.product_cat}</td>
                <td style={{ padding: '10px', textAlign: 'center'}}>
                  {item.quantity_conversion !== 0
                    ? parseFloat(item.inventory_quantity / item.quantity_conversion).toFixed(1)
                    : item.inventory_quantity}
                </td>
                  <td style={{padding: '10px', textAlign: 'center'}}>
                    <button 
                      className='action-button'
                      onClick={() => {
                        const subunit = (item.product_tier === 1 && (item.quantity_conversion > 1)) ? item.product_subunit : null
                        handleEditQuantityModal(locationName, item.product_id, item.product_name, item.inventory_quantity, item.quantity_conversion, item.discontinue, item.product_unit, item.product_description, subunit, item.product_tier, item)
                      }} 
                      style={{fontSize: 'small', width: '5vw', minWidth: '48px', maxWidth: '64px'}}>
                        Edit
                    </button>
                  </td>
                  <td style={{padding: '10px', textAlign: 'center'}}>
                  <button 
                    className={item.has_active_orders && item.has_active_orders !== "" ? "action-button-white" : 'action-button'}
                    onClick={() => handleOrder(item.product_name, item.product_id, 'inventory', item.manufacturer_name, item.product_unit, item.product_description, item)}
                    style={{
                      fontSize: 'small', 
                      width: '5vw', 
                      minWidth: '48px', 
                      maxWidth: '64px'
                    }}
                    title={item.has_active_orders && item.has_active_orders !== "" ? `You already have order(s) for that product in status(es): ${item.has_active_orders.split(',').join(', ')}` : ''}>
                      {permissionCode === 25 || permissionCode === 26 || ((permissionCode === 22 || permissionCode === 27) && orderThreshold === 0) ? 'Request' : 'Order'}
                  </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div> 

      <>

  </>

    {/* Order Modal */}
    <OrderModal isLocationBlocked={isLocationBlocked} isOpen={orderModalIsOpen} setIsOpen={setOrderModalIsOpen} product={selectedProduct?.item} isLoadingAdditionalRecommendations={isLoadingAdditionalRecommendations}  submitOrder={submitOrder} quantity={1} permissionCode={permissionCode} orderThreshold={orderThreshold}/>
    <Modal
        overlayClassName="white-modal-overlay"
        className="white-modal"
        isOpen={editQuantityModalIsOpen}
        onRequestClose={() => {
          setEditQuantityModalIsOpen(false);
          resetEditModal();
        }}
        style={{
          content: {
              width: '50%',
              height: '60%',
          }
        }}
        
      >
        <div style={{marginTop: '-5px', borderRadius: '8px'}}>
          <div style={{flex: '0 1 auto', }}>
            <ModalHeader description={'Edit details for'} title={`${selectedProduct?.title} ${selectedProduct?.productDescription? `- ${ selectedProduct?.productDescription }` : ''}`}/>
          </div>
          <div
            style={{
              paddingLeft:'20px',
              display: 'flex',
              columnGap: '40px',
            }}
          >
            <h3
              onClick={() => {
                if (modalTab !== 'Edit Inventory') {
                  setEditQuantityNumber(parseFloat((selectedProduct?.currentQuantity / selectedProduct?.quantityConversion)));
                  setModalTab('Edit Inventory');
                }
              }}
              style={{
                marginBottom: '0px',
                // backGroundColor: modalTab === 'Edit Inventory' ? 'white' : '#F5F5F5',
                color: '#000000',
                padding: '0px',
                borderBottom:
                  modalTab === 'Edit Inventory'
                    ? '2px solid black'
                    : '2px solid transparent',
                fontWeight: modalTab === 'Edit Inventory' ? 'bold' : 'normal', // Added font weight
                cursor: 'pointer', // Set cursor to pointer
              }}
            >
              Edit Inventory
            </h3>
            
            <h3
              onClick={() => {
                if (modalTab !== 'Move Product') {
                  setEditQuantityNumber(0);
                  setModalTab('Move Product');
                }
              }}
              style={{
                marginBottom: '0px',
                // marginLeft: '15px',
                color: '#000000',
                borderBottom:
                  modalTab === 'Move Product'
                    ? '2px solid black'
                    : '2px solid transparent',
                fontWeight: modalTab === 'Move Product' ? 'bold' : 'normal', // Added font weight
                cursor: 'pointer', // Set cursor to pointer
              }}
            >
              Move Product
            </h3>
            <h3
              onClick={() => {
                if (modalTab !== 'Free Product') {
                  setEditQuantityNumber(0);
                  setModalTab('Free Product');
                }
              }}
              style={{
                // marginLeft: '15px',
                marginBottom: '0px',
                color: '#000000',
                borderBottom:
                  modalTab === 'Free Product'
                    ? '2px solid black'
                    : '2px solid transparent',
                fontWeight: modalTab === 'Free Product' ? 'bold' : 'normal', // Added font weight
                cursor: 'pointer', // Set cursor to pointer
              }}
            >
              Free Product
            </h3>
          </div>
        </div>

        {modalTab === 'Edit Inventory' && (
          <div style={{marginLeft: '20px',}}>
            {selectedProduct && (
              <div>
                <br/>                
                <UnitConversionDisplay quantity={editQuantityNumber} setQuantity={setEditQuantityNumber} quantityConversion={selectedProduct.quantityConversion} units={selectedProduct?.productUnit} subUnits={selectedProduct.productSubunit} tier={selectedProduct.productTier}/>
                
                <div className="input-group">
                  <br />
                  <div style={{float: 'left'}}>
                    Change quantity at {locationName} from {(selectedProduct?.currentQuantity / selectedProduct?.quantityConversion).toFixed(1)} {selectedProduct.productUnit === 'box' ? 'box(es)' : `${ selectedProduct.productUnit }(s)`} to {editQuantityNumber ? editQuantityNumber.toFixed(1) : 0 } {
                          selectedProduct.productUnit === 'box' ? 'box(es)' : `${ selectedProduct.productUnit }(s)`}.
                  </div>
                  <br />
                  <br />

                  <button
                    onClick={() => {
                      submitEditQuantity('edit');
                    }}
                    className='action-button'
                  >
                    Submit Edit
                  </button>
                  <br />
                  <br />
                </div>

                <div className="input-group">
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <h4 style={{fontWeight:'normal'}}>Discontinue / Remove:</h4>
                    <button className="info-button" onClick={toggleDiscontinueRemovePopUp}>&#x2139;</button>
                  </div>
                  <button
                    className='action-button'
                    onClick={() => {
                      setShowRemoveConfirmation(true);
                      setEditOption('Discontinue');
                    }}
                    style={{
                      marginBottom: '20px',
                      marginRight:'10px'
                    }}
                  >
                    {selectedProduct?.discontinue === 0 ? 'Discontinue' : 'Activate'}
                  </button>

                  <button
                    className='decline-button'
                    onClick={() => {
                      setShowRemoveConfirmation(true);
                      setEditOption('Remove');
                    }}
                    style={{
                      fontSize:'small',
                      marginBottom: '20px'
                    }}
                  >
                    Remove
                  </button>
                </div>

              </div>
            )}
          </div>
        )}
        {modalTab === 'Move Product' && (
          <div style={{marginLeft: '20px', }}>
            {selectedProduct && (
              <div>
                <br />                
                <UnitConversionDisplay quantity={editQuantityNumber} setQuantity={setEditQuantityNumber} quantityConversion={selectedProduct.quantityConversion} units={selectedProduct?.productUnit} subUnits={selectedProduct.productSubunit} tier={selectedProduct.productTier} />
                <br />

                <div style={{display: 'inline-block', width: 'auto', }}>
                  <div style={{display: 'flex', gap: '8px'}}>
                    <div style={{width: '50px', margin: '0px'}}>From: </div>
                    <div style={{float: 'left'}}>
                      {locationName}
                    </div>
                  </div>

                  <br />

                  <div style={{display: 'flex'}}>
                    <div style={{width: '50px', margin: '0px'}}>To: </div>
                    <select
                      className='select-box'
                      value={selectedDestination}
                      onChange={(e) => setSelectedDestination(e.target.value)}
                      style={{marginLeft: '4px',  boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)', border: '1px solid #87D3D7', fontStyle: 'italic', }}
                    >
                      <option value="">Select Destination</option>
                      {locations
                        .filter(location => location.group_name === locations.find(location => location.location_name === locationName).group_name) // Filter by group_name
                        .filter(location => location.location_name !== locationName)
                        .sort()
                        .map(location => (
                          <option key={location.location_name} value={location.location_name}>
                            {location.location_name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <br />
                  <div className="input-group">
                    <div style={{float: 'left'}}>
                      Move {editQuantityNumber ? editQuantityNumber : 0} {selectedProduct.productUnit === 'box' ? 'box(es)' : `${ selectedProduct.productUnit }(s)`} of {selectedProduct?.productName} from {locationName} to {selectedDestination ? selectedDestination : '(please select a destination)'}.
                    </div>

                    <br />
                    <br />
                    <button
                      className='action-button'
                      onClick={() => {
                        submitEditQuantity('move');
                      }}
                      style={{
                        marginBottom:'20px'
                      }}
                    >
                      Submit Transfer
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {modalTab === 'Free Product' && (
          <div style={{marginLeft: '20px', }}>
            <br />            
            <UnitConversionDisplay quantity={editQuantityNumber} setQuantity={setEditQuantityNumber} quantityConversion={selectedProduct.quantityConversion} units={selectedProduct?.productUnit} subUnits={selectedProduct.productSubunit} tier={selectedProduct.productTier} />
            <br />

            <div className="input-group">
              <div style={{float: 'left'}}>
                Add {editQuantityNumber ? editQuantityNumber : 0} of {selectedProduct?.productUnit === 'box' ? 'box(es)' : `${ selectedProduct?.productUnit }(s)`} free promotional product to inventory.
              </div>

              <br />
              <br />
              <button
                className='action-button'
                onClick={() => {
                  submitEditQuantity('add');
                }}
                style={{
                  marginBottom: '20px'
                }}
              >
                Submit Quantity
              </button>
            </div>


          </div>
        )}
    </Modal>

      {isAdvancedModalOpen && (
        <AdvancedSearchModal title={modalSource} fetchData={() => fetchInventoryData(locationName, setInventoryData, true)} userSearchTerm={modalSource === 'Advanced Search' ? searchTerm : ''} handleOrder={handleOrder} permissionCode={permissionCode} isAdvancedModalOpen={isAdvancedModalOpen} setIsAdvancedModalOpen={setIsAdvancedModalOpen} locationName={locationName} />
      )}

    {/* Confirmation Modal for Remove Action */}
    {showRemoveConfirmation && 
      <ConfirmationModal
        isOpen={showRemoveConfirmation}
        onClose={() => setShowRemoveConfirmation(false)}
        onConfirm={async (confirmed) => {
          if (confirmed){
            if (editOption === 'Discontinue'){
              await updateDiscontinueProduct(selectedProduct?.productId, locationName, selectedProduct?.discontinue === 0 ? 1 : 0, username); 
              await fetchInventoryData(locationName, setInventoryData, true);
              resetEditModal();
              toast.success("Status updated.");
            } else if (editOption === 'Remove'){
              await handleRemove(selectedProduct?.productId, locationName, username); 
              await fetchInventoryData(locationName, setInventoryData, true);
              resetEditModal();
              toast.success("Product removed.");
            }
          }
        }}
        message={`Are you sure you want to ${editOption === 'Discontinue' ? 'update the discontinued status of' : 'remove'} ${selectedProduct?.title ? selectedProduct.title : 'this product'}?`}
      />
    }

    {isDiscontinueRemovePopupVisible && (
      <div className="popup">
        <div className="popup-inner">
          <h3>Discontinue vs. Remove:</h3>
          <p>
            <b>Discontinue</b>: The product will remain in your inventory but won't be recommended.
          </p>
          <p>
            <b>Remove</b>: Permanently deletes the product from your inventory. This action cannot be undone.
          </p>
          <button className="submit-button" onClick={toggleDiscontinueRemovePopUp} style={{width: '100px',border: '3px solid #31bac1', color: 'white', backgroundColor: '#31bac1', padding: '5px', marginTop: '20px',  cursor: 'pointer'}}>Close</button>
        </div>
      </div>
    )}


    <ToastContainer />

    {showownerApproval && (
      <OwnerApprovalModal
        isOpen={showownerApproval}
        onClose={() => setShowOwnerApproval(false)}
      />
    )}

    {inventoryReportModalOpen && (
      <InventoryReportModal
        isOpen={inventoryReportModalOpen}
        onClose={() => setInventoryReportModalOpen(false)}
        locationName={locationName}
      />
    )}

  </div>
  );
};

export default Inventory;