import React, {useEffect, useState, useRef} from 'react';
import loadingSpinner from '../assets/images/loading.gif'; // Import your loading spinner image
import Modal from 'react-modal';
import UnitConversionDisplay from '../features/inventory/UnitConversionDisplay';
import ModalHeader from './ModalHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {getPrice} from '../utils/mapping/FetchData';

const OrderModal = ({isOpen, setIsOpen, product, submitOrder, quantity, isLoadingAdditionalRecommendations, isLocationBlocked, permissionCode, orderThreshold=0}) => {
    const [orderQuantity, setOrderQuantity] = useState(0);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [orderComment, setOrderComment] = useState('');
    const [orderUrgency, setOrderUrgency] = useState('5+ business days');
    const [title, setTitle] = useState('');
    const [priceProduct, setPriceProduct] = useState('');

    const fetchPrice = async () => {
        try {
            const price = await getPrice(process.env.REACT_APP_BACKEND_URL, product?.product_id, product?.location_name);
            setPriceProduct(price?.min_price ?? 0);  // Set price if available, otherwise set to 0
        } catch (error) {
            console.error('Error fetching price:', error);  // Log the error for debugging
            setPriceProduct(0);  // Default to 0 in case of an error
        }
    };

    useEffect(() => {
        setOrderQuantity(quantity);
        setOrderUrgency(product?.order_urgency ? product?.order_urgency : '5+ business days');
        setTitle(`${ product?.product_name } ${ product?.product_description ? `- ${ product?.product_description }` : '' }`);
        setOrderComment(product?.client_comment ? product?.client_comment : '');
        fetchPrice();
    }, [isOpen]);

    const closeModal = () => {
        setOrderQuantity(quantity);
        setOrderUrgency('5+ business days');
        setIsOpen(false);
        setOrderComment('');
    };

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    const submit = () => {
        submitOrder(orderQuantity, orderUrgency, orderComment);
    };
    
    return (<Modal
        overlayClassName="white-modal-overlay"
        className="white-modal"
        isOpen={isOpen}
        onRequestClose={closeModal}
    >
        <ModalHeader description={'Order details for'} title={title} />

        {product && (
            <div style={{paddingLeft: '20px', paddingRight: '20px', position: 'relative'}}>
                {isLocationBlocked && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%', // Ensure full screen width
                            height: '100%', // Ensure full screen height
                            backgroundColor: 'rgba(0, 0, 0, 0.75)',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            zIndex: 10, // Ensure it's always above other elements
                            overflow: 'hidden', // Prevent any unwanted scrollbars
                        }}
                    >
                        <div style={{ padding: '20px', maxWidth: '500px', textAlign: 'center' }}>
                            <h1>Ordering is unavailable at this time.</h1>
                            <p>Our records indicate that your account has overdue invoices. Please settle them to resume placing orders.</p>
                            <br />
                            <button className='action-button' onClick={closeModal}>
                                Close
                            </button>
                        </div>
                    </div>
                )}

                {/* Display warning if there are active orders */}
                {product?.has_active_orders && product.has_active_orders !== "" && (
                    <div style={{
                        backgroundColor: '#FFF3CD',
                        border: '1px solid #FFEEBA',
                        color: '#856404',
                        padding: '10px',
                        borderRadius: '5px',
                        marginBottom: '10px',
                        marginTop: '10px',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '10px' }} />
                        <p style={{ margin: 0 }}>You already have order(s) for this product in status(es): {product.has_active_orders.split(',').join(', ')}</p>
                    </div>
                )}

                <div style={{marginBottom: '10px', width: '150px'}}>
                    <h3 style={{marginTop: '10px', marginBottom: '10px'}}>Quantity</h3>
                </div>
                <UnitConversionDisplay
                    quantity={orderQuantity}
                    setQuantity={setOrderQuantity}
                    quantityConversion={product.quantity_conversion}
                    units={product.product_unit}
                />

                <div style={{marginBottom: '10px', }}>
                    <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                        <h3 style={{margin: 0, marginTop: '10px'}}>Preferred Delivery Timeline</h3>
                        <button className="info-button" onClick={togglePopup}>&#x2139;</button>
                    </div>
                    <select className='select-box' style={{marginTop: '8px', marginBottom: '8px', width: '150px'}}
                        value={orderUrgency} onChange={(e) => setOrderUrgency(e.target.value)}>
                        <option value="1 business day">1 business day</option>
                        <option value="2-3 business days">2-3 business days</option>
                        <option value="3-5 business days">3-5 business days</option>
                        <option value="5+ business days">5+ business days</option>
                    </select>
                </div>

                {orderUrgency === '1 business day' && (
                    <p style={{marginTop: '-8px', color: 'black'}}>
                        Attention: Medvelle will process this order ASAP, additional delivery charges will apply.
                    </p>
                )}

                {orderUrgency === '2-3 business days' && (
                    <p style={{marginTop: '-8px', color: 'black'}}>
                        Attention: Additional delivery charges may apply depending on the supplier and your location.
                    </p>
                )}

                {(orderUrgency === '3-5 business days' || orderUrgency === '5+ business days') && (
                    <p style={{marginTop: '-8px', color: 'black'}}>
                        Thank you for being proactive with orders, you just saved on additional delivery charges!
                    </p>
                )}

                {isPopupVisible && (
                    <div className="popup">
                        <div className="popup-inner">
                            <p>Please indicate your preferred delivery timeline - Medvelle will make every effort to meet it.</p>
                            <p>The official order cutoff for next business day orders is 2PM ET. Please contact <a href={`mailto:${process.env.REACT_APP_PROCUREMENT_EMAIL}`} style={{textDecoration: 'none', color: '#D46B45'}}><b>{process.env.REACT_APP_PROCUREMENT_EMAIL}</b></a> for any urgent matters.</p>
                            <p>For maximum savings and minimized shipping costs, Medvelle encourages placing orders ahead of time.</p>

                            <button style={{backgroundColor: '#31bac1', color: 'black', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '8px'}} onClick={togglePopup}>Close</button>
                        </div>
                    </div>
                )}

                <div >
                    <h3>Order Comment</h3>
                    <textarea
                        style={{width: '50%', marginRight: '20px', maxWidth: '95%'}}
                        className='select-box'
                        value={orderComment}
                        maxLength="1500" // Limiting to 20 characters
                        onChange={(e) => setOrderComment(e.target.value)}
                    />
                </div>

                {isLoadingAdditionalRecommendations ? (
                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                        <div
                            style={{marginTop: '5px'}}
                        >
                            <img
                                src={loadingSpinner}
                                alt="loading-spinner"
                                style={{
                                    height: '20px',
                                    marginRight: '10px',
                                }}
                            />
                        </div>
                        Medvelle AI searching for additional recommendations...
                    </div>
                ) : (
                    <button
                        className='action-button'
                        disabled={isLocationBlocked}
                        onClick={() => {
                            closeModal();
                            submit();
                        }}
                        style={{ marginTop: '20px', marginBottom: '20px' }}
                    >
                    {permissionCode === 25 || permissionCode === 26 || ((permissionCode === 22 || permissionCode === 27) && (orderThreshold === 0 || orderThreshold < (orderQuantity ?? 0) * (priceProduct ?? 0))) 
                        ? 'Submit Request' 
                        : 'Submit Order'}
                    </button>
                )}
            </div>
        )}
    </Modal>);
};

export default OrderModal;