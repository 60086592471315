import React, {useEffect, useState, useRef} from 'react';
import SupportForm from '../features/support/SupportForm';
import {closeAbortController, createAbortController, getAbortSignal} from '../utils/controller/abortUtils';
import Table from '../components/Table';
import {fetchSpendSummary, fetchLocationInvoices, fetchInvoicePdf, fetchBackupSheet} from '../utils/finance/invoiceInfoUtils';
import {formatDateTextNoConversion} from '../utils/time_zones/TimeZones';
import InvoiceDetailsModal from '../features/finance/InvoiceDetailsModal';
import DonutChart from '../components/visualizations/DonutChart';
import {getOutstandingOrders} from '../utils/orders/orderInfoUtils';
import {getOutstandingInvoices} from '../utils/invoices/invoiceUtils';
import {filterByTime} from '../utils/tables/searchUtils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import IconTooltip from '../components/IconTooltip';
import TimeseriesBarChart from '../components/visualizations/TimeseriesBarChart';

const Finance = ({locationName, permissionCode}) => {
    const abortControllerRef = useRef(null);  // Create a ref to hold the AbortController
    const contentRef = useRef();
    const [isInvoiceDetailsOpen, setIsInvoiceDetailsOpen] = useState(false);
    const [spendSummary, setSpendSummary] = useState({});
    const [outstandingOrdersAmount, setOutstandingOrdersAmount] = useState(null);
    const [outstandingInvoicesAmount, setOutstandingInvoicesAmount] = useState(null);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [showContact, setShowContact] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [selectedTimeframe, setSelectedTimeframe] = useState('all');
    const [timeFilter, setTimeFilter] = useState('all');

    const uninvoicedDescription = 'Uninvoiced spend is the amount you have spent in orders through Medvelle that has not yet been invoiced to your account.'
    const upcomingInvoiceDescription = 'Total amount owed in invoices that have not yet come due.'
    const timeRanges = [{key: '1 MONTH', value: 'Last Month'}, {key: '3 MONTH', value: 'Last 3 Months'}, {key: '6 MONTH', value: 'Last 6 Months'}, {key: '1 YEAR', value: 'Last Year'}, {key: 'all', value: 'All Time'}];

    useEffect(() => {
        createAbortController(abortControllerRef);
        const signal = getAbortSignal(abortControllerRef);

        fetchLocationInvoices({locationName, setInvoices, signal});
        fetchSpendSummary({locationName, timeframe: selectedTimeframe, setSpendSummary, signal});
        getOutstandingOrders({locationName, setOutstandingOrdersAmount, signal});
        getOutstandingInvoices({locationName, setOutstandingInvoicesAmount, signal});

        contentRef.current.style.marginLeft = '250px';
        contentRef.current.style.marginRight = '50px';

        return () => {
            closeAbortController(abortControllerRef);
        };
    }, [locationName]);

    useEffect(()=>{
        fetchSpendSummary({locationName, timeframe: selectedTimeframe, setSpendSummary});

    },[selectedTimeframe]);

    const openInvoiceDetailsModal = (invoice) => {
        setSelectedInvoice(invoice);
        setIsInvoiceDetailsOpen(true);
    };

    const toggleContactInfo = () => {
        setShowContact(!showContact);
    };

    return (
        <div ref={contentRef} style={{textAlign: 'left'}}>
            <div style={{textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h2 style={{marginTop: '27px', display: 'flex', fontSize: '40px', fontWeight: 'bold', color: '#87D3D7'}}>Finance</h2>
                    <div
                        style={{
                            marginLeft: '10px',
                            color: '#F49C7D',
                            fontSize: '30px',
                            fontStyle: 'italic',
                            border: '1px solid #F49C7D',
                            borderRadius: '8px',
                            padding: '2px 4px',
                            whiteSpace: 'nowrap',
                            textIndent: '-2px',
                            display: 'inline-block',
                        }}
                    >
                        BETA
                    </div>
                </div>

                <button className="support-btn" onClick={toggleContactInfo} style={{backgroundColor: '#87D3D7', marginTop: '0px', marginRight: '-5px'}}>
                    <h1 style={{fontSize: '20px'}}>?</h1>
                </button>
                {showContact && (
                    <SupportForm callBack={setShowContact} locationName={locationName} permissionCode={permissionCode} />
                )}

            </div>

            <div style={{display: 'flex', gap: '20px', flexWrap: "wrap",  }}>
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '50%', minWidth: '500px', flexGrow: '1', flexShrink: '0',}}>
                    <div className='basic-card' style={{height: '50%', overflow: 'visible'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h3 style={{marginTop: '0px', marginBottom: '8px'}}>Uninvoiced Spend</h3>
                            <IconTooltip icon={
                                <FontAwesomeIcon
                                    icon={faInfoCircle} // Toggle between lock and unlock icons
                                    className={'icon-hover-accent'}

                                />}
                                hoverText={uninvoicedDescription}
                            />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <h1>${outstandingOrdersAmount?.toLocaleString() ?? 0}</h1>
                        </div>
                    </div>
                    <div className='basic-card' style={{height: '50%', overflow: 'visible'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h3 style={{marginTop: '0px', marginBottom: '8px'}}>Upcoming Invoices</h3>
                            <IconTooltip icon={
                                <FontAwesomeIcon
                                    icon={faInfoCircle} 
                                    className={'icon-hover-accent'}

                                />}
                                hoverText={upcomingInvoiceDescription}
                            />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <h1>${outstandingInvoicesAmount?.toLocaleString() ?? 0}</h1>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '50%', minWidth: '500px', flexGrow: '1', flexShrink: '0'}}>
                    <div className='basic-card' style={{height: '100%'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h3 style={{marginTop: '0px', marginBottom: '8px'}}>Past Invoices</h3>
                            <select className='select-box' style={{minWidth: '100px', width: '30%'}} onChange={(e)=>setSelectedTimeframe(e.target.value)} value={selectedTimeframe}>
                                {timeRanges.map((range, index) => (
                                    <option key={index} value={range.key}>
                                        {range.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div style={{height: '30vh'}}>
                            {Object.keys(spendSummary).length > 0 ? (
                                <DonutChart dataDict = {spendSummary}  />
                            )
                                : 
                                <div style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center', flexGrow: '1', flexShrink: '0'}}>No Spend</div>
                                }
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="basic-card"
                style={{
                    marginTop: "20px",
                    height: "40vh",
                    maxWidth: "100%",
                    minWidth: "460px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <h3 style={{ marginTop: "0px", marginBottom: "8px" }}>Invoices History</h3>
                    <select
                    id="time"
                    className="select-box"
                    value={timeFilter}
                    onChange={(e) => setTimeFilter(e.target.value)}
                    style={{ width: "15%" }}
                    >
                    <option value="all">All</option>
                    <option value="last6months">Last 6 Months</option>
                    <option value="last3months">Last 3 Months</option>
                    <option value="lastmonth">Last Month</option>
                    <option value="thismonth">This Month</option>
                    <option value="nextmonth">Next Month</option>
                    <option value="next3months">Next 3 Months</option>
                    <option value="next6months">Next 6 Months</option>
                    </select>
                </div>
                <div
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    }}
                >
                    <TimeseriesBarChart
                    data={filterByTime(invoices, timeFilter, "payment_date")
                        .map((item) => ({
                        ...item,
                        payment_date: new Date(item?.payment_date)?.toISOString(), // Extract only the YYYY-MM-DD part
                        }))
                        .map((i) => ({
                        due_date: i.payment_date,
                        total: i.total,
                        invoice_id: i.invoice_id,
                        }))}
                    />
                </div>
            </div>

            <div style={{marginTop: '20px', marginBottom: '50px', minWidth: '450px'}}>
                <h2>Invoices</h2>
                <Table
                    data={invoices.map(invoice => {
                        return ({
                            ...invoice,
                            total_amount: invoice.total,
                            backup_sheet:
                                <button 
                                    className="action-button"
                                    onClick={async (event) => {
                                        event.stopPropagation(); // Prevents event from bubbling up
                                        const blob = await fetchBackupSheet(invoice.invoice_id, invoice.recipient);
                                        const url = window.URL.createObjectURL(blob);
                                
                                        // Create a link element and set its attributes
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', `invoice_${invoice.invoice_id}_${invoice.recipient}.xlsx`);
                                
                                        // Append the link to the body and click it programmatically
                                        document.body.appendChild(link);
                                        link.click();
                                
                                        // Clean up by revoking the URL object
                                        window.URL.revokeObjectURL(url);
                                    }}
                                    disabled={invoice?.invoice_type !== 'Reimbursement'}
                                    style={{
                                        cursor: invoice?.invoice_type !== 'Reimbursement' ? 'none' : 'pointer',
                                        opacity: invoice?.invoice_type !== 'Reimbursement' ? 0.5 : 1
                                    }}
                                >
                                    Download Backup
                                </button>,
                            invoice_pdf:
                                <button className='action-button' onClick={async (event) => {
                                        event.stopPropagation(); // Prevents event from bubbling up
                                        const blob = await fetchInvoicePdf(invoice.invoice_id);
                                        const link = document.createElement('a');
                                        const url = URL.createObjectURL(blob);
                                        link.href = url;
                                        link.setAttribute('download', `invoice_${ (invoice.invoice_id) }_${ invoice.recipient }.pdf`);

                                        // Append the link to the body and click it programmatically
                                        document.body.appendChild(link);
                                        link.click();

                                        // Clean up by revoking the URL object
                                        window.URL.revokeObjectURL(url);
                                    }}
                                    disabled={invoice?.invoice_type !== 'Reimbursement'}
                                    style={{
                                        cursor: invoice?.invoice_type !== 'Reimbursement' ? 'none' : 'pointer',
                                        opacity: invoice?.invoice_type !== 'Reimbursement' ? 0.5 : 1
                                    }}
                                >
                                    Download PDF
                                </button>
                        });
                    })}
                    columns={[
                        'Invoice #',
                        'Invoice Date',
                        'Due Date',
                        'Total Amount',
                        'Invoice Type',
                        'Backup Sheet',
                        'Invoice PDF'
                    ]}
                    mapping={{
                        'Invoice #': 'invoice_id',
                        'Invoice Date': 'invoice_date',
                        'Due Date': 'payment_date',
                        'Total Amount': 'total_amount',
                        'Invoice Type': 'invoice_type',
                        'Backup Sheet' : 'backup_sheet',
                        'Invoice PDF' : 'invoice_pdf'
                    }}
                    placements={{
                        'Backup Sheet':'center',
                        'Invoice PDF':'center'
                    }}
                    prefix={{'Total Amount': '$', 'Avg. Sales Price Per Unit': '$'}}
                    isHoverable={true}
                    isClickable={true}
                    onClick={openInvoiceDetailsModal}
                    height={'400px'}
                    
                    columnWidths={{'Invoice #': '5%'}}
                    callBack={
                        {
                            'Invoice Date': (d) => {return d ? formatDateTextNoConversion(d) : d;}, // TODO displays the day before.
                            'Due Date': (d) => {return d?  formatDateTextNoConversion(d) : d;},
                            'Avg. Sales Price Per Unit': (number) => {return parseFloat(number?.toFixed(2))?.toLocaleString();},
                            'Total Amount': (n) => {return n?.toLocaleString();}
                        }
                    }
                    searchColumns={['invoice_date', 'payment_date', 'total_amount', 'invoice_id','invoice_type']}
                    dropDownColumns={['invoice_type']}
                />
            </div>
            {selectedInvoice && (
                <InvoiceDetailsModal isOpen={isInvoiceDetailsOpen} setIsOpen={setIsInvoiceDetailsOpen} onClose={() => setIsInvoiceDetailsOpen(false)} invoice={selectedInvoice} />
            )}

        </div>
    );
};

export default Finance;
