import React, {useEffect, useState, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import loadingSpinner from '../../assets/images/loading.gif';
import {sortArrayByAttribute, handleSortChange, getArrow} from '../../utils/tables/sortUtils';
import {webSearch, getGlobalInventory, addToInventory, getProductClass} from '../../utils/inventory/inventoryManagement';
import {fuzzySearch} from '../../utils/tables/searchUtils';
import UnitConversionDisplay from './UnitConversionDisplay'
import ModalHeader from '../../components/ModalHeader';
import {fetchUsername} from '../../utils/users/userUtils';

const AdvancedSearchModal = ({title, fetchData, handleOrder, isAdvancedModalOpen, setIsAdvancedModalOpen, userSearchTerm, permissionCode, locationName, orderThreshold=0}) => {
    const [searchTerm, setSearchTerm] = useState(userSearchTerm);
    const [submittedSearchTerm, setSubmittedSearchTerm] = useState('');
    const [globalInventory, setGlobalInventory] = useState([]);
    const [webSearchResults, setWebSearchResults] = useState([]);
    const [filteredGlobalInventory, setFilteredGlobalInventory] = useState([]);
    const [displayedOptions, setDisplayedOptions] = useState([]);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [productQuantity, setProductQuantity] = useState(0);
    const [isLoadingWeb, setIsLoadingWeb] = useState(false);
    const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);
    const [sortInvAttribute, setSortInvAttribute] = useState('');
    const [sortInvAsc, setSortInvAsc] = useState(true);
    const [sortWebAttribute, setSortWebAttribute] = useState('');
    const [sortWebAsc, setSortWebAsc] = useState(true);
    const [hasSearched, setHasSearched] = useState(false);
    const [username, setUsername] = useState('');
    const [selectedManufacturer, setSelectedManufacturer] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(''); // State to manage selected category
    
    const uniqueCategories = Array.from(new Set(filteredGlobalInventory.map(item => item.product_cat)));

    const uniqueManufacturers = Array.from(new Set(filteredGlobalInventory.map(item => item.manufacturer_name)));

    const inputRef = useRef(null);

    const columnWidths = {
        name: "30%",
        description: "30%",
        manufacturer: "10%",
        category: "10%",
        add: "10%",
        order: "10%"
    };

    useEffect(() => {
        getGlobalInventory(locationName, setGlobalInventory);
        if (userSearchTerm) {
            setSearchTerm(userSearchTerm);
        }
        const focusInput = () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        };

        fetchUsername(setUsername)

        // Use setTimeout to ensure the focus happens after the render
        const timeoutId = setTimeout(focusInput, 0);

        // Cleanup the timeout in case the component unmounts
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(()=>{
        if (!submittedSearchTerm || globalInventory.length === 0) {
            return 
        }
        // Perform the fuzzy search on global inventory
        const newFilteredInventory = fuzzySearch([...globalInventory].map(item => ({
            ...item,
            product_full: `${ item.product_name } ${ item.product_description }`
        })), submittedSearchTerm, ['product_name', 'product_description', 'manufacturer_name', 'product_cat', 'product_full'], 0.3);

        setFilteredGlobalInventory(newFilteredInventory);
    }, [globalInventory, submittedSearchTerm])

    useEffect(() => {
        const sortedInv = sortArrayByAttribute([...filteredGlobalInventory], sortInvAttribute, sortInvAsc);
        setFilteredGlobalInventory(sortedInv);
    }, [sortInvAsc, sortInvAttribute]);

    useEffect(() => {
        const sortedWeb = sortArrayByAttribute([...webSearchResults], sortWebAttribute, sortWebAsc);
        setWebSearchResults(sortedWeb);
    }, [sortWebAsc, sortWebAttribute]);

    useEffect(()=>{
        setDisplayedOptions(filteredGlobalInventory.filter(item => ((item.product_cat === selectedCategory) || !selectedCategory) && ((item.manufacturer_name === selectedManufacturer) || !selectedManufacturer)))
    }, [filteredGlobalInventory, selectedManufacturer, selectedCategory])

    const handleModalClose = () => {
        setIsAdvancedModalOpen(false);
        setSearchTerm('');
        setSortInvAsc(true);
        setSortInvAttribute('');
        setSortWebAsc(true);
        setSortWebAttribute('');
        setIsLoadingWeb(false);
        setIsLoadingGlobal(false);
        setWebSearchResults([]);
        setHasSearched(false);
    };

    const closeAddModal = () => {
        setProductQuantity(0);
        setAddModalOpen(false);
    }

    const handleSearch = async (e) => {
        setSelectedCategory('');
        setSelectedManufacturer('');
        try {
            e.preventDefault();
            if (!searchTerm) {
                toast.error('Please enter a search query.');
                return;
            }

            setIsLoadingWeb(true);
            setIsLoadingGlobal(true);

            // Perform the fuzzy search on global inventory
            setSubmittedSearchTerm(searchTerm);

            setIsLoadingGlobal(false);

            // Perform the web search
            const results = await webSearch(searchTerm);
            setWebSearchResults(results);

            // Update search status
            setHasSearched(true);
        } catch (error) {
            console.error('Error during search:', error);
            toast.error('There was an issue running your search.');
        } finally {
            // Ensure loading states are reset in case of error or success
            setIsLoadingWeb(false);
            setIsLoadingGlobal(false);
        }
    };

    return <Modal
        overlayClassName="modal-overlay"
        // className="modal-content"
        isOpen={isAdvancedModalOpen}
        onRequestClose={handleModalClose}

        style={{
            content: {
                height: 'fit-content',
                background: '#f5f5f5',
                border: '2px solid #87D3D7',
                borderRadius: '8px',
                height: '85vh',
                zIndex: 2
                // overflow: 'auto'
            },
            overlay: {
                zIndex: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
        }}
    >
        <div>
            <div style={{display: 'flex'}}>
                <h1 style={{marginTop: '0px', }}>{title}</h1>
                <button className="support-button"
                    style={{
                        marginLeft: 'auto', // Push the button to the far right
                        fontSize: 'medium',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        height: '2.5rem',
                        width: '2.5rem'


                    }} onClick={handleModalClose}>
                    <FontAwesomeIcon style={{
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignContent: 'center',
                        alignItems: 'center'
                    }} icon={faTimes} />
                </button>

            </div>
            <form onSubmit={handleSearch} style={{display: 'flex', alignItems: 'center', marginTop: '8px'}}>
                <input
                    className='select-box'
                    type="text"
                    ref={inputRef}
                    placeholder="Search Product Name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}

                    style={{marginTop: '-4px', padding: '10px', width: '20vw', marginRight: '20px'}}
                />
                <button
                        className='support-button'
                        type="submit"
                        disabled={isLoadingWeb || isLoadingGlobal}
                        style={{
                            marginTop: '-4px',
                        }}
                >
                        Search
                </button>
            
            </form>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>


                <div style={{
                    width: '2px', // Width of the vertical line
                    height: '', // Full height of the parent container
                    backgroundColor: 'transparent', // Color of the line
                    margin: '0 10px', // Spacing between divs
                    // display: 'none'
                }}></div>



            </div>
            <br />
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{width: '45vw'}}>
                    
                    <div style={{display: 'flex', width: '100%',marginBottom:'10px', alignItems:'center'}}>
                        <div style={{marginRight:'20px'}}>
                            <h3 style={{
                                position: 'sticky', // Stick the header
                                top: 0
                            }}>Global Inventory</h3>
                        </div>
                        <select
                            className='select-box'
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            style={{fontStyle: 'italic', width: '15vw', marginRight: '20px'}}
                        >
                            <option value="">All Categories</option>
                            {uniqueCategories.sort().map(product_cat => (
                                <option key={product_cat} value={product_cat}>
                                    {product_cat}
                                </option>
                            ))}
                        </select>


                        <select
                            className='select-box'
                            value={selectedManufacturer}
                            onChange={(e) => setSelectedManufacturer(e.target.value)}
                            style={{ fontStyle: 'italic', width: '15vw', marginRight: '20px'}}
                        >
                            <option value="">All Manufacturers</option>
                            {uniqueManufacturers.sort().map(manufacturer => (
                                <option key={manufacturer} value={manufacturer}>
                                    {manufacturer}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{height: '65vh', overflow: 'auto', width: '100%', border: '2px solid #87D3D7', }}>
                        {
                            isLoadingGlobal ? (
                                <div style={{alignText: 'center', display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', }}>
                                    <img
                                        src={loadingSpinner}
                                        alt="loading-spinner"
                                        style={{
                                            marginTop: '-15px',
                                            height: '20px',
                                            marginRight: '10px',
                                        }} />
                                </div>
                            ) :
                        <table style={{
                            width: '100%',
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                            position: 'relative' // Ensure positioning context
                        }}>
                            <thead style={{
                                position: 'sticky', top: 0
                            }}>
                                <tr style={{fontSize: '13px', backgroundColor: '#fffff', color: '#000000'}}>
                                    <th onClick={() => handleSortChange('product_name', sortInvAttribute, setSortInvAttribute, sortInvAsc, setSortInvAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.name, textAlign: 'left', backgroundColor: '#ffffff', cursor: 'pointer'}}>Product Name {getArrow('product_name', sortInvAttribute, sortInvAsc)}</th>
                                    <th onClick={() => handleSortChange('product_description', sortInvAttribute, setSortInvAttribute, sortInvAsc, setSortInvAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.description, textAlign: 'left', backgroundColor: '#ffffff', cursor: 'pointer'}}>Description {getArrow('product_description', sortInvAttribute, sortInvAsc)}</th>
                                    <th onClick={() => handleSortChange('manufacturer_name', sortInvAttribute, setSortInvAttribute, sortInvAsc, setSortInvAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.manufacturer, textAlign: 'center', backgroundColor: '#ffffff', cursor: 'pointer'}}>Manufacturer {getArrow('manufacturer_name', sortInvAttribute, sortInvAsc)}</th>
                                    <th onClick={() => handleSortChange('product_cat', sortInvAttribute, setSortInvAttribute, sortInvAsc, setSortInvAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.category, textAlign: 'center', backgroundColor: '#ffffff', cursor: 'pointer'}}>Category {getArrow('product_cat', sortInvAttribute, sortInvAsc)}</th>
                                    <th style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.add, textAlign: 'center', backgroundColor: '#ffffff'}}>Add</th>
                                    <th style={{borderBottom: '2px solid black', padding: '10px', width: columnWidths.order, textAlign: 'center', backgroundColor: '#ffffff'}}>Order</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(filteredGlobalInventory.length === 0 && (!hasSearched || globalInventory === 0)) && (
                                    <tr>
                                        <td colSpan="6" style={{textAlign: 'center', verticalAlign: 'middle', fontSize: '16px', fontStyle: 'italic', padding: '50px 10px', height: '350px'}}>
                                            Search product name and results from the global inventory will populate here.
                                        </td>
                                    </tr>
                                )}
                                {(displayedOptions.length === 0 && hasSearched && !globalInventory.length !== 0) && (
                                    <tr>
                                        <td colSpan="6" style={{textAlign: 'center', verticalAlign: 'middle', fontSize: '16px', fontStyle: 'italic', padding: '50px 10px', height: '350px'}}>
                                            No results in global inventory.
                                        </td>
                                    </tr>
                                )}
                                {displayedOptions.map((item, index) => (
                                    <tr key={item.id} style={{fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff'}} >
                                        <td style={{padding: '10px', }}>{item.product_name}</td>
                                        <td style={{padding: '10px', }}>{item.product_description}</td>
                                        <td style={{padding: '10px', textAlign: 'center'}}>{item.manufacturer_name}</td>
                                        <td style={{padding: '10px', textAlign: 'center'}}>{item.product_cat}</td>
                                        <td style={{padding: '10px', textAlign: 'center'}}>
                                            <button
                                                className='support-button'

                                                onClick={() => {
                                                    setSelectedProduct(item);
                                                    setAddModalOpen(true);
                                                }}
                                                style={{
                                                    fontSize: 'small', width: '5vw', padding: '8px', minWidth: '48px', maxWidth: '64px'
                                                }}>
                                                Add
                                            </button>
                                        </td>
                                        <td style={{padding: '10px', textAlign: 'center'}}>
                                            <button
                                                className='support-button'
                                                onClick={() => {handleOrder(item.product_name, item.product_id, 'inventory', item.manufacturer_name, item.product_unit, item.product_description, item);}}
                                                style={{
                                                    fontSize: 'small', width: '5vw', padding: '8px', minWidth: '48px', maxWidth: '64px'
                                                }}>
                                                {permissionCode === 25 || permissionCode === 26 || ((permissionCode === 22 || permissionCode === 27) && orderThreshold === 0) ?
                                                'Request' : 'Order'}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                            }
                    </div>

                </div>
                <div style={{
                    width: '2px', // Width of the vertical line
                    height: '', // Full height of the parent container
                    backgroundColor: 'transparent',//'#87D3D7', // Color of the line
                    margin: '0 10px' // Spacing between divs
                }}></div>

                <div style={{width: '45vw'}}>
                    <div style={{width: '100%'}}>
                        <h3 style={{
                            position: 'sticky', // Stick the header
                            top: 0,
                        }}>Web Products</h3>
                    </div>
                    <div style={{
                        height: '65vh', overflow: 'auto', width: '100%', alignText:'center', border: '2px solid #87D3D7',}}>
                        
                        {isLoadingWeb ? (
                                <div style={{alignText: 'center', display: 'flex', height:'100%', justifyContent: 'center', alignItems: 'center',}}>
                                    <img
                                        src={loadingSpinner}
                                        alt="loading-spinner"
                                        style={{
                                            marginTop: '-15px',
                                            height: '20px',
                                            marginRight: '10px',
                                        }} />
                                </div>                            
                        ) : 
                        <table style={{
                            width: '100%',
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                            position: 'relative' // Ensure positioning context
                        }}>
                            <thead style={{
                                position: 'sticky', // Stick the header
                                top: 0, // Stick to the top of the table
                                zIndex: 5
                            }}>


                                <tr style={{fontSize: '13px', backgroundColor: 'white'}}>

                                    <th onClick={() => handleSortChange('title', sortWebAttribute, setSortWebAttribute, sortWebAsc, setSortWebAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: '40%', textAlign: 'left'}}>Product Name {getArrow('title', sortWebAttribute, sortWebAsc)}</th>
                                    <th onClick={() => handleSortChange('price', sortWebAttribute, setSortWebAttribute, sortWebAsc, setSortWebAsc)} style={{borderBottom: '2px solid black', padding: '10px', width: '20%', textAlign: 'left'}}>Price {getArrow('price', sortWebAttribute, sortWebAsc)}</th>
                                    <th style={{borderBottom: '2px solid black', padding: '10px', width: '20%', textAlign: 'center'}}>Image</th>
                                    <th style={{borderBottom: '2px solid black', padding: '10px', width: '20%', textAlign: 'center'}}>Place Order</th>
                                </tr>

                            </thead>
                            <tbody>
                                {(webSearchResults.length === 0 && !hasSearched) && (
                                    <tr>
                                        <td colSpan="4" style={{textAlign: 'center', verticalAlign: 'middle', fontSize: '16px', fontStyle: 'italic', padding: '50px 10px', height: '350px'}}>
                                            Search product name and results from web search will populate here.
                                        </td>
                                    </tr>
                                )}
                                {(webSearchResults.length === 0 && hasSearched) && (
                                    <tr>
                                        <td colSpan="4" style={{textAlign: 'center', verticalAlign: 'middle', fontSize: '16px', fontStyle: 'italic', padding: '50px 10px', height: '350px'}}>
                                            No results for web search.
                                        </td>
                                    </tr>
                                )}

                                {webSearchResults.length > 0 && (
                                    webSearchResults.slice(0, 10).map((result, index) => {
                                        return (

                                            <tr key={index} style={{fontSize: '13px', backgroundColor: index % 2 === 0 ? '#ebf4f5' : '#ffffff'}} >
                                                <td style={{padding: '10px', textAlign: 'left'}}>{result.title || 'N/A'}</td>
                                                <td style={{padding: '10px', textAlign: 'left'}}>
                                                    {`$${ result.minPrice } - $${ result.maxPrice }`}
                                                    <br />
                                                    <span style={{fontSize: '10px', color: 'gray'}}>Price TBD by Medvelle</span>
                                                </td> {/* Display price range with '$' */}
                                                <td style={{padding: '10px', textAlign: 'center'}}>
                                                    {result.thumbnail ? (
                                                        <div className="zoom-image-container">
                                                            <img

                                                                src={result.thumbnail}
                                                                alt={result.title || 'Image'}
                                                                className="thumbnail-image"
                                                            />
                                                            <div className="popup-overlay" >
                                                                <img
                                                                    src={result.thumbnail}
                                                                    alt={result.title || 'Image'}
                                                                    className="popup-image"
                                                                    style={{zIndex: 10}}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </td>
                                                <td style={{padding: '5px', textAlign: 'center'}}>
                                                    <button
                                                        className='support-button'
                                                        onClick={() => handleOrder(result.title, '', 'web', result)} // Pass the result data to the handleOrder function
                                                        style={{
                                                            fontSize: 'small',
                                                            padding: '8px',
                                                            width: '5vw',
                                                            minWidth: '48px',
                                                            maxWidth: '68px'
                                                        }}
                                                    >
                                                        {permissionCode === 25 || permissionCode === 26 || ((permissionCode === 22 || permissionCode === 27) && orderThreshold === 0) ?
                                                        'Request' : 'Order'}
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>}
                    </div>

                </div>


            </div>

            <div style={{display: 'flex'}}>


            </div>

        </div>
        <Modal
            overlayClassName="white-modal-overlay"
            className="white-modal"
            isOpen={addModalOpen}
            onRequestClose={closeAddModal}
        >
            {selectedProduct && (
                <>
                    <ModalHeader description={'Inventory details for'} title={`${selectedProduct?.product_name} ${selectedProduct?.product_description ? `- ${ selectedProduct?.product_description}`: ''}`}/>
                    <div style={{marginLeft: '20px', marginRight:'20px', marginBottom:'20px'}}>
                        <div>
                            <div style={{marginTop: '20px', textAlign: 'center'}}>
                                <UnitConversionDisplay quantity={productQuantity} setQuantity={setProductQuantity} quantityConversion={selectedProduct.quantity_conversion} units={selectedProduct?.product_unit} subUnits={selectedProduct.product_subunit} tier={selectedProduct.product_tier} />
                            </div>
                            <br />
                            <div style={{float: 'left'}}>
                                Add {productQuantity.toFixed(2)} {selectedProduct?.product_unit === 'box' ? 'box(es)' : `${ selectedProduct?.product_unit }(s)`} to {locationName}'s inventory.
                            </div>
                            <br/>
                            <button className='support-button' onClick={async () => {
                                const productClassAvg = await getProductClass(selectedProduct.product_id);
                                const result = await addToInventory(selectedProduct.product_id, locationName, productQuantity * selectedProduct.quantity_conversion, productClassAvg, username);
                                if (result) {
                                    toast.success(`${ selectedProduct.product_name } was successfully added to your inventory.`);
                                    fetchData();
                                    closeAddModal();
                                    setIsAdvancedModalOpen(false);
                                }
                                else {
                                    toast.error(`There was an issue adding ${ selectedProduct.product_name } to your inventory.`);
                                }
                            }} style={{ fontSize: 'small', marginTop:'20px'}}>
                                Add Product
                            </button>

                        </div>
                    </div>
                </>
                
               
            )}
        </Modal>
    </Modal>;
};

export default AdvancedSearchModal;