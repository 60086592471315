import {fetchWithAuth} from "../authentication/authenticationUtils";
import {fetchLocationPricingStyle} from "../locations/locationsUtils";
import {fetchUser} from "../users/userUtils";

export const fetchLocationInvoices = async ({locationName, setInvoices, signal=undefined}) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/location-invoices/${locationName}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setInvoices(data);
        return data

    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching locations data:', error);
        }
        return [];
    }
}

export const fetchInvoiceBundleDetails = async ({invoiceId, setInvoiceBundleInfo, signal = undefined}) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/invoice-bundle-details/${ invoiceId }`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();

        setInvoiceBundleInfo(data);
        return data;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching locations data:', error);
        }
        return [];
    }
};

export const fetchInvoiceOrderDetails = async ({invoiceId, setInvoiceOrderInfo, signal = undefined}) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/invoice-order-details/${ invoiceId }`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();

        setInvoiceOrderInfo(data);
        return data;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching locations data:', error);
        }
        return [];
    }
};


export const fetchInvoicePaymentHistory = async ({locationName, setInvoicePaymentHistory, signal = undefined}) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/invoice-payment-history/${ locationName }`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();

        setInvoicePaymentHistory(data);
        return data;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching locations data:', error);
        }
        return [];
    }
};

export const fetchSpendSummary = async ({locationName, timeframe, setSpendSummary, signal = undefined}) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/spend-summary/${ locationName }`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({timeframe}),
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) { 
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSpendSummary(data);
        return data;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching locations data:', error);
        }
        return [];
    }
};

export const fetchUninvoicedOrders = async ({locationName, setUninvoicedOrders, signal = undefined}) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/uninvoiced-orders/${ locationName }`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            ...(signal ? {signal} : {})  // Conditionally add `signal` if it’s provided
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUninvoicedOrders(data);
        return data;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching locations data:', error);
        }
        return [];
    }
};

export const groupByProductCategoryFullPricing = (bundles, ordersPerBundle) => {
    const groupedData = {};
    let subTotal = 0;
    let totalShipping = 0;
    let totalTaxes = 0;

    bundles.forEach(bundle => {
        const orders = ordersPerBundle[bundle.order_code] || [];
        orders.forEach(order => {
            if (!groupedData[order.product_cat]) {
                groupedData[order.product_cat] = {
                    total_price: 0,
                    description: 'Product Reimbursement',
                    quantity: 1,
                    product_cat: order.product_cat,
                    orders: [],
                };
            }
            groupedData[order.product_cat].total_price += order.order_quantity * order.unit_price;
            groupedData[order.product_cat].orders.push(order);
            subTotal += order.order_quantity * order.unit_price;
        });

        totalShipping += bundle.shipping;
        totalTaxes += bundle.taxes;

    });

    // Filter out categories with total_price 0
    const filteredData = Object.keys(groupedData).reduce((acc, key) => {
        if (groupedData[key].total_price > 0) {
            acc[key] = groupedData[key];
        }
        return acc;
    }, {});
    return filteredData;
};

export const fetchInvoicePdf = async (invoiceId) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/invoice-pdf/${invoiceId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.blob();
        return data;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching locations data:', error);
        }
        return [];
    }
};

export const fetchBackupSheet = async (invoiceId, locationName) => {
    try {
        const pricingStyle = await fetchLocationPricingStyle(locationName);
        const user = await fetchUser(() => {});
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/download-invoice`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({invoice_id: invoiceId, pricingStyle, location_name: locationName, username: user.name})
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.blob();
        return data;
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching locations data:', error);
        }
        return [];
    }
};