
import React, {useEffect, useState} from 'react';
import {formatDateText, formatDateTextNoConversion} from '../../utils/time_zones/TimeZones';
import {fetchInvoiceBundleDetails, fetchInvoiceOrderDetails} from '../../utils/finance/invoiceInfoUtils';
import Table from '../../components/Table';

const InvoiceDetailsContent = ({invoice, }) => {
    const [invoiceBundleInfo, setInvoiceBundleInfo] = useState([]);
    const [invoiceOrderInfo, setInvoiceOrderInfo] = useState([]);
    const [groupedInvoiceOrders, setGroupedInvoiceOrders] = useState([]);

    useEffect(() => {
        const invoiceId = invoice.invoice_id;
        fetchInvoiceOrderDetails({invoiceId, setInvoiceOrderInfo});
        fetchInvoiceBundleDetails({invoiceId, setInvoiceBundleInfo});
    }, []);

    useEffect(() => {
        const grouped = invoiceOrderInfo.reduce((acc, obj) => {
            const key = obj.order_code;  // The attribute to group by
            if (!acc[key]) {
                acc[key] = [];  // Initialize an empty array if it doesn't exist
            }
            acc[key].push(obj);  // Add the object to the corresponding array
            return acc;
        }, {});

        const tableValues = Object.fromEntries(
            Object.entries(grouped).map(([key, value]) => [
                key, <Table
                    data={value}
                    columns={[
                        "Product",
                        "Description",
                        "Quantity",
                        "Unit Price",
                        "Total Price"
                    ]}
                    mapping={
                        {
                            "Product": 'product_name',
                            "Description": 'product_description',
                            "Quantity": 'order_quantity',
                            "Unit Price": "unit_price",
                            "Total Price": 'total_price'
                        }
                    }
                    placements={
                        {
                        }
                    }
                    callBack={{
                        'Unit Price': (n) => n.toFixed(2),
                        "Total Price": (n) => n.toFixed(2),
                    }}
                    prefix={{
                        "Unit Price": '$',
                        "Total Price": '$'
                    }}
                />
            ])
        );
        setGroupedInvoiceOrders(tableValues);

    }, [invoiceOrderInfo]);

    return (
        <div style={{margin: '20px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', gap: '20px', marginBottom: '20px'}}>
                <div
                    className={"basic-card"}
                    style={{position: 'relative', cursor: '', width: '30%', justifyContent: 'center'}}  // Make the card container relative for positioning the banner
                    onClick={() => { }}
                >
                    <h3 style={{color: 'gray', marginRight: '20px', marginBottom: '8px', marginTop: '0px'}}>Total</h3>

                    <b style={{fontSize: '30px', color: 'black'}}>
                        {(
                            <>${invoice.total.toLocaleString()}</>
                        )}
                    </b>
                </div>
                <div
                    className={"basic-card"}
                    style={{position: 'relative', cursor: '', width: '80%', justifyContent: 'center'}}  // Make the card container relative for positioning the banner
                    onClick={() => { }}
                >
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{justifyContent: 'center', marginTop: '0px'}}>
                            <div style={{color: 'gray', fontWeight: 'bold', fontSize: 'small', marginBottom: '4px'}}>Invoice Date</div>
                            <b style={{fontSize: '20px', color: 'black'}}>
                                {(
                                    <>{formatDateTextNoConversion(invoice.invoice_date)}</>
                                )}
                            </b>
                        </div>
                        <div style={{justifyContent: 'center', marginTop: '0px'}}>
                            <div style={{color: 'gray', fontWeight: 'bold', fontSize: 'small', marginBottom: '4px'}}>Due Date</div>
                            <b style={{fontSize: '20px', color: 'black'}}>
                                {(
                                    <>{formatDateTextNoConversion(invoice.payment_date)}</>
                                )}
                            </b>
                        </div>

                        <div style={{justifyContent: 'center', marginTop: '0px'}}>
                            <div style={{color: 'gray', fontWeight: 'bold', fontSize: 'small', marginBottom: '4px'}}>Invoice Type</div>
                            <b style={{fontSize: '20px', color: 'black'}}>
                                {(
                                    <>{invoice.invoice_type}</>
                                )}
                            </b>
                        </div>

                        <div style={{justifyContent: 'center', marginTop: '0px'}}>
                            <div style={{color: 'gray', fontWeight: 'bold', fontSize: 'small', marginBottom: '4px'}}>Credits</div>
                            <b style={{fontSize: '20px', color: 'black'}}>
                                {(
                                    <>${invoice?.medvelle_network_discounts ?? 0}</>
                                )}
                            </b>
                        </div>
                    </div>
                </div>
            </div>
            {invoice.invoice_type === 'Reimbursement' ? (
                <>
                    <h3>Orders</h3>
                    <div style={{marginTop: '10px'}}>
                        <Table
                            data={invoiceBundleInfo}
                            columns={[
                                'Bundle Date',
                                'Subtotal',
                                'Shipping',
                                'Taxes',
                                'Discount',
                                'Total',
                            ]}
                            mapping={{
                                'Bundle Date': 'bundle_date',
                                'Subtotal': 'subtotal',
                                'Shipping': 'shipping',
                                'Taxes': 'taxes',
                                'Discount': 'discount',
                                'Total': 'total_price'
                            }}
                            isHoverable={true}
                            height={'250px'}
                            columnWidths={{'Product Name': '20%'}}
                            prefix={{
                                'Subtotal': '$',
                                'Shipping': '$',
                                'Taxes': '$',
                                'Discount': '$',
                                'Total': '$'
                            }}
                            callBack={
                                {
                                    'Bundle Date': (d) => {return formatDateText(d);}, // TODO displays the day before.
                                    'Subtotal': (n) => n.toFixed(2),
                                    'Shipping': (n) => n.toFixed(2),
                                    'Taxes': (n) => n.toFixed(2),
                                    'Discount': (n) => n.toFixed(2),
                                    'Total': (n) => n.toFixed(2),
                                }
                            }
                            idColumn={'order_code'}
                            isExpandable={true}
                            subData={
                                groupedInvoiceOrders
                            }
                        />
                    </div>
                </>
            )
                :
                <div>
                    <Table
                        data={[
                            ...(invoice.monthly_fee > 0 ? [{Total: invoice.monthly_fee, type: 'Monthly Fee'}] : []),
                            ...(invoice.onboarding_fee > 0 ? [{Total: invoice.onboarding_fee, type: 'Onboarding Fee'}] : [])
                        ]}
                        columns={[
                            'Type',
                            'Total',
                        ]}
                        mapping={{
                            'Type' :'type',
                            'Total': 'Total',
                        }}
                        isHoverable={true}
                        height={'250px'}
                        prefix={{
                            'Total': '$'
                        }}
                        callBack={
                            {
                                'Total': (n) => n.toFixed(2),
                            }
                        }
                    />
                    </div>}

        </div>
    );
};

export default InvoiceDetailsContent;