import {sortArrayByAttribute, handleSortChange, getArrow} from '../utils/tables/sortUtils';
import React, {useEffect, useState, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {fuzzySearch} from '../utils/tables/searchUtils';
const Table = ({
    data,
    columns,
    mapping,
    placements = {},
    columnWidths = {},
    isHoverable = true,
    isClickable = false,
    onClick = (row) => { },
    subData = {},
    idColumn,
    isExpandable,
    rowHeight,
    height,
    width,
    prefix = {},
    callBack = {},
    searchColumns = [],
    dropDownColumns = []
}) => {
    const [sortAsc, setSortAsc] = useState(true);
    const [sortAttribute, setSortAttribute] = useState('');
    const [sortedData, setSortedData] = useState([]);
    const [expandedRow, setExpandedRow] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [attributeOptions, setAttributeOptions] = useState({});

    const [selectedAttributes, setSelectedAttributes] = useState({});
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        setSortedData(data);

        const uniqueValuesDict = dropDownColumns.reduce((acc, attribute) => {
            const uniqueValues = [...new Set([...data].map(item => item[attribute]))];
            acc[attribute] = uniqueValues;
            return acc;
        }, {});
        setAttributeOptions(uniqueValuesDict)


    }, [data]);

    useEffect(() => {
        setSortedData(sortArrayByAttribute([...data], sortAttribute, sortAsc));
    }, [sortAsc, sortAttribute]);

    useEffect(()=>{
        const searchArray = fuzzySearch(sortedData, searchTerm, searchColumns)
        const filteredArray = searchArray.filter(item => {
            return Object.keys(selectedAttributes).every(key => {
                return selectedAttributes[key] ? item[key] === selectedAttributes[key] : true;
            });
        });
        setFilteredData(filteredArray)
    }, [sortedData, searchTerm, selectedAttributes])

    // Sets a bundle to be displayed.
    const handleRowClick = (rowId) => {
        if (expandedRow.includes(rowId)) {
            setExpandedRow(expandedRow.filter(id => id !== rowId));
        } else {
            // If the bundle is not expanded, add it to the list of expanded bundles
            setExpandedRow([...expandedRow, rowId]);
        }
    };

    const handleAttributeChange = (columnName, e) => {
        setSelectedAttributes(prevData => ({
            ...prevData, // Keep previous data
            [columnName]: e.target.value, // Update only the key-value pair you want
        }));
    }

    return (
        <>
            {(searchColumns.length > 0 || dropDownColumns.length > 0) &&  (
                <div style={{display:'flex', gap:'10px', marginBottom:'10px'}}>
                    {searchColumns.length > 0 && (
                        <input
                            type="text"
                            className='select-box'
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    )}
                    
                    {dropDownColumns.map((value, index) => (
                        <div key={index}>
                            <select className='select-box' onChange={(e)=>handleAttributeChange(value, e)}>
                                <option key={index} value={''}>
                                    {`Select ${ Object.keys(mapping).find(key => mapping[key] === value)}`}
                                </option>
                                {attributeOptions[value]?.map((atValue, index) => (
                                    <option key={index} value={atValue}>
                                        {atValue}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ))}
                    
                    
                </div>
            )}
            <div className="table-container" style={{height: height, width: width}}>
                <table className={isHoverable ? "table hover-effect" : "table"}>
                    <thead>
                        <tr>
                            {isExpandable && <th style={{borderBottom: '2px solid black', padding: '10px', width: '5%'}}></th>}
                            {columns.map((column, index) => (
                                <th key={index}
                                    style={{width: columnWidths[column] ? columnWidths[column] : '10%', textAlign: placements[column] ? placements[column] : 'left'}}
                                    onClick={() => handleSortChange(mapping[column], sortAttribute, setSortAttribute, sortAsc, setSortAsc)}>
                                    {column} {getArrow(mapping[column], sortAttribute, sortAsc)}
                                </th>
                            )
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((row, rowIndex) => {
                            return (
                                <>
                                    <tr key={rowIndex} onClick={isClickable ? () => onClick(row) : () => { }} style={{cursor: isClickable ? 'pointer' : '', height: rowHeight ? rowHeight : ''}}>
                                        {isExpandable && (
                                            <td
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRowClick(row[idColumn]);
                                                }}
                                                style={{textAlign: 'center', cursor: 'pointer'}}
                                            >
                                                <span>
                                                    {expandedRow.includes(row[idColumn]) ?
                                                        <FontAwesomeIcon icon={faAngleUp} /> :
                                                        <FontAwesomeIcon icon={faAngleDown} />
                                                    }
                                                </span>
                                            </td>
                                        )}
                                        {columns.map((column, colIndex) => (
                                            <td key={colIndex} style={{textAlign: placements[column] ? placements[column] : 'left'}}>
                                                {prefix[column] ? prefix[column] : ''}{callBack[column] ? callBack[column](row[mapping[column]]) : row[mapping[column]]}
                                            </td>
                                        )
                                        )}
                                    </tr>

                                    {(expandedRow.includes(row[idColumn]) && subData[row[idColumn]]) && (
                                        <tr style={{backgroundColor: rowIndex % 2 === 1 ? '#ebf4f5' : 'white'}}>
                                            {/* Render order details */}
                                            <td colSpan="1"></td>
                                            <td colSpan={columns.length}>
                                                <div style={{zIndex: 0, border: '1px solid black', display: 'flex', justifyContent: 'left', flexDirection: 'column', width: '99.95%', overflow: 'hidden', position: 'relative'}}>
                                                    {prefix[idColumn] ? prefix[idColumn] : ''}{subData[row[idColumn]]}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                    }
                                </>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};


export default Table;