import { fetchWithAuth } from '../authentication/authenticationUtils';

/**
 * Fetches the ID of the last support ticket.
 * @returns {string|Array} - The last support ticket ID if successful, or an empty array if an error occurs.
 */
export const fetchLastSupportTicketId = async () => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/last-support-ticket-id`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        if (!response.ok) {
            throw new Error('Error getting last support ticket id.');
        }

        const data = await response.json();

        return data["lastTicketId"];
    } catch (error) {
        console.error('Error with support tickets:', error);
        return [];
    }
}

/**
 * Fetches all support tickets and passes the data to a callback function.
 * @param {Function} callBack - The callback function to handle the fetched data.
 * @returns {Array} - The array of support tickets if successful, or an empty array if an error occurs.
 */
export const fetchSupportTickets = async (callBack) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/support-tickets`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        if (!response.ok) {
            throw new Error('Error getting tickets.');
        }

        const responseData = await response.json();
        callBack(responseData);
        return responseData;
    } catch (error) {
        console.error('Support ticket error:', error);
        return [];
    }
};

/**
 * Fetches recent updates related to a support ticket, user, or location based on the selected tab.
 * @param {Function} setRecentSupportUpdates - Function to update the recent support updates state.
 * @param {Object} ticket - The ticket object containing relevant information.
 * @param {string} tab - The selected tab to determine the type of updates to fetch.
 * @returns {Array} - An array of recent updates if successful, or an empty array if an error occurs.
 */
export const fetchRecentSupportUpdates = async (setRecentSupportUpdates, ticket, tab) => {
    try {
        if (tab === 'Ticket History') {
            const response = await fetchWithAuth(process.env.REACT_APP_BACKEND_URL + `/support-ticket-updates/${ ticket.ticket_id }`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setRecentSupportUpdates(data);
        }
        else if (tab === 'User History') {
            const response = await fetchWithAuth(process.env.REACT_APP_BACKEND_URL + '/support-user-updates', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: ticket.username
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            setRecentSupportUpdates(data);
        }
        else if (tab === 'Location History') {
            const response = await fetchWithAuth(process.env.REACT_APP_BACKEND_URL + '/support-location-updates', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    location_name: ticket.location_name
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setRecentSupportUpdates(data);
        }
        else {
            return [];
        }

    } catch (error) {
        console.error('Error fetching recent updates:', error);
    }
};

/**
 * Initiates the download of a Blob object as a file.
 * @param {Blob} blob - The Blob object to be downloaded.
 * @param {string} filename - The name of the file to be downloaded.
 */
export const downloadBlob = (blob, filename) => {
    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element and click it to start the download
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the object URL and removing the anchor
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};

/**
 * Parses a string of comma-separated email addresses into an array of individual email addresses.
 * Each email address is trimmed of any leading or trailing whitespace.
 *
 * @param {string} input - A comma-separated string of email addresses.
 * @returns {string[]} An array of email addresses, each trimmed of leading and trailing whitespace.
 *
 * @example
 * // Example usage
 * const emails = parseEmails("   example1@example.com, example2@example.com ,example3@example.com   ");
 * console.log(emails);
 * // Output: ["example1@example.com", "example2@example.com", "example3@example.com"]
 */
export const parseEmails = (input) => {
    const emailArray = input.split(/,\s*/);
    return emailArray.map(email => email.trim());
};

export const newSupportTicket = async (supportData) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/support-ticket`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(supportData)
        });
        if (!response.ok) {
            throw new Error('Error getting tickets.');
        }

        return true;
    } catch (error) {
        console.error('Support ticket error:', error);
        return false;
    }
};

export const updateSupportTicketConversation = async (conversationData, ticketId) => {
    try {
        const response = await fetchWithAuth(`${ process.env.REACT_APP_BACKEND_URL }/support-ticket-conversation/${ticketId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(conversationData)
        });
        if (!response.ok) {
            throw new Error('Error getting tickets.');
        }

        return true;
    } catch (error) {
        console.error('Support ticket error:', error);
        return false;
    }
};