import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/style/index.css'; // Make sure to create a corresponding CSS file for styling
import SupportForm from '../features/support/SupportForm'

import { signOut } from "firebase/auth";
import { auth } from "../context/Config";
import { fetchWithAuth, clearCookies } from "../utils/authentication/authenticationUtils"

const Account = ({locationName, call, permissionCode}) => {

  const [accountData, setAccountData] = useState([]);

  const contentRef = useRef();

  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch inventory data from the server
    fetchWithAuth(`${call}/account?locationName=${locationName}`) 
      .then(response => response.json())
      .then(data => {
        setAccountData(data);
      })
      .catch(error => console.error('Error fetching inventory data:', error));

    // Make the PUT request to fetch notification settings for the specified userEmail
    fetchWithAuth(`${call}/notification-settings`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userEmail })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch notification settings');
      }
      return response.text(); // Get the response body as text
    })
    .then(data => {
      // Convert the response text (which is a JSON string) to JSON
      let parsedData = JSON.parse(JSON.parse(data).settings);
      // Assuming the settings are returned as a JSON object
      setNotificationSettings(parsedData);
    })
    .catch(error => console.error('Error fetching notification settings:', error));

    contentRef.current.style.marginLeft = '250px';
    contentRef.current.style.marginRight = '50px';

    
  }, [locationName]);


  const locationAccountData = accountData
    .filter(item => item.location_name === locationName);
  

  const handleEdit = (event) => {
    event.preventDefault();
    toast.info('Contact Medvelle Support to edit account details, thank you.');
  };
    
  const handleSignOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      clearCookies();
      navigate('/sign-in');
      console.log("Logged out successfully");
    }).catch((error) => {
      console.error("Error signing out", error);
    });
  };

  const [showContact, setShowContact] = useState(false);
  const toggleContactInfo = () => {
    setShowContact(!showContact);
  }

  const [notificationSettings, setNotificationSettings] = useState({
    Internal: {
      Recommendations: false,
      Spotcheck: false,
      ProductLoss: false,
      SpecialOffer: false,
      MedvelleCommunications: false
    },
    Email: {
      Recommendations: false,
      Spotcheck: false,
      ProductLoss: false,
      SpecialOffer: false,
      MedvelleCommunications: false
    }
  });

  const handleCheckboxChange = (category, type) => {
    setNotificationSettings(prevSettings => ({
      ...prevSettings,
      [type]: {
        ...prevSettings[type],
        [category]: !prevSettings[type][category]
      }
    }));
  };
  
  const handleAdvancedSettings = () => {
    navigate('/settings')
  }

  const handleSaveSettings = () => {
    // Send the updated notification settings to an API to save the data
    fetchWithAuth(`${call}/save-notification-settings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userEmail, notificationSettings })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to save notification settings');
        }
        console.log('Notification settings saved successfully');
      })
      .catch(error => console.error('Error saving notification settings:', error));

      alert("Settings saved!");
  };

  // Mapping object to convert category names
  const categoryMapping = {
    Recommendations: "Recommendations",
    Spotcheck: "Spotcheck",
    ProductLoss: "Product Loss",
    SpecialOffer: "Special Offer",
    MedvelleCommunications: "Medvelle Communications"
  };

  
  return (
      <div ref={contentRef} style={{ textAlign: 'left' }}>
    
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>  
      <h1 style={{ marginTop: '27px', fontSize: '40px', fontWeight: 'bold', color: '#87D3D7',  }}>Your Account</h1>
      {(permissionCode === 11 || permissionCode === 21) && (
        <button className="logout-button" style={{marginLeft:'562px'}} onClick={() => handleAdvancedSettings()}>Advanced Settings</button>
      )}

      <div style={{display: 'flex', flexDirection: 'row',  }}>
      <button className="logout-button" onClick={() => handleSignOut()} > Log Out</button>

      <button className="support-btn" onClick={toggleContactInfo} style={{ backgroundColor: '#87D3D7', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',}} >
    
    <h1 style={{ fontSize: '20px' }}>?</h1>

    </button>

    </div>
    
    {showContact && (
      <SupportForm callBack={setShowContact} locationName={locationName} permissionCode={permissionCode} />
    )}

    



    </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    
        {locationAccountData.map((user, index) => (
          
          <div style={{ width: '45%', margin: '10px' }}>
          
          <form key={index} className = 'info-form' >
          <h2 style={{textAlign: 'center', width: '100%'}}>Practice Information</h2>
            <label className='form-labels'>
              Group Name
            </label>
            <input type="text" className="text-box" value={user.group_name}/>
            <label className='form-labels'>
              Practice Name
            </label>
            <input type="text" className="text-box" value={user.location_name}/>
            <label className='form-labels'>
              Contact Name
            </label>
            <input type="text" className="text-box" value={user.contact_name}/>
            <label className='form-labels'>
              Contact Email 
            </label>
            <input type="text" className="text-box" value={user.email}/>
            <label className='form-labels'>
              Contact Phone
            </label>
            <input type="text" className="text-box" value={user.phone}/>
            <label className='form-labels'>
              Onboarding Obligations 
            </label>
            <input type="text" className="onboarding-text-box" value='All Documents Provided'/>

            <button type="button" className="edit-button" onClick={handleEdit}>Edit</button>
              
          </form>
          
          </div>
          
        ))}
       
       {locationAccountData.map((user, index) => (
      <div style={{ flexDirection: 'column', width: '45%', margin: '10px' }}>
      <form  key={index} className = 'info-form'>
      <h2 style={{textAlign: 'center', width: '100%'}}>Payment Information</h2>
            <label className='form-labels'>
              Billing Address 
            </label>
            <input type="text" className="text-box" value={`${user.billing_street}, ${user.billing_city}, ${user.billing_state}, ${user.billing_zip}`}/>
            <label className='form-labels'>
              Product Billing Terms
            </label>
            <input type="text" className="text-box" value={`${user.billing_terms}`}/>

            <button type="button" className="edit-button" onClick={handleEdit}>Edit</button>

            <h2 style={{textAlign: 'center', width: '100%'}}>Ordering Information</h2>
            <label className='form-labels'>
              Shipping Address 
            </label>
            <input type="text" className="text-box" value={`${user.shipping_street}, ${user.shipping_city}, ${user.shipping_state}, ${user.shipping_zip}`}/>
            
            <label className='form-labels'>
              Delivery Restrictions 
            </label>
            <input type="text" className="text-box" value={user.delivery_restrictions || "None"}/>
            
            <button type="button" className="edit-button" onClick={handleEdit}>Edit</button>
          </form>
          
          </div>

          ))}

          <div className = 'info-form' style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '45%', margin: '10px' }}>
            <div className="settings-section" style={{width:'60%'}}>
              <h2>Notifications</h2>
              {Object.entries(notificationSettings.Internal).map(([category, checked]) => (
                <div key={category} className="checkbox-container" style={{textAlign: 'left'}}>
                  <input
                    type="checkbox"
                    id={`internal-${category}`}
                    checked={checked}
                    style={{height:'15px', width:'15px'}}
                    onChange={() => handleCheckboxChange(category, 'Internal')}
                  />
                  <label htmlFor={`internal-${category}`}>{categoryMapping[category]}</label>
                </div>
              ))}
            </div>
        <br/>
            <div className="settings-section" style={{width:'60%'}}>
              <h2>Emails</h2>
              {Object.entries(notificationSettings.Email).map(([category, checked]) => (
                <div key={category} className="checkbox-container" style={{textAlign: 'left'}}>
                  <input
                    type="checkbox"
                    id={`email-${category}`}
                    checked={checked}
                    onChange={() => handleCheckboxChange(category, 'Email')}
                    style={{ height: '15px', width: '15px' }}
                  />
                  <label htmlFor={`email-${category}`}>{categoryMapping[category]}</label>
                </div>
              ))}
            </div>
            <br/>
            <button className="edit-button" onClick={handleSaveSettings}>Save Settings</button>

          </div>

        </div>
    
        

          
        <ToastContainer />

         
      </div>
      )
  }

export default Account;
