import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import "chartjs-adapter-date-fns";
import { eachDayOfInterval, format, parseISO } from "date-fns";

Chart.register(...registerables, annotationPlugin);

const TimeseriesBarChart = ({ data }) => {
    if (!data || data.length === 0) return <p>No data available</p>;

    const parsedData = data.map(item => ({
        due_date: parseISO(item.due_date),
        total: item.total
    }));

    const minDate = new Date(Math.min(...parsedData.map(item => item.due_date)));
    const maxDate = new Date(Math.max(...parsedData.map(item => item.due_date)));
    const dateRange = (maxDate - minDate) / (1000 * 60 * 60 * 24);
    
    let timeUnit = "day";
    if (dateRange > 90) timeUnit = "month";
    else if (dateRange > 30) timeUnit = "week";

    const allDates = eachDayOfInterval({ start: minDate, end: maxDate }).map(date => format(date, "yyyy-MM-dd"));
    const totalsByDate = {};
    parsedData.forEach(({ due_date, total }) => {
        const dateStr = format(due_date, "yyyy-MM-dd");
        totalsByDate[dateStr] = (totalsByDate[dateStr] || 0) + total;
    });

    const beforeToday = parsedData.filter(d => new Date(d.due_date) < new Date());
    const afterToday = parsedData.filter(d => new Date(d.due_date) >= new Date());

    const chartData = {
        labels: allDates,
        datasets: [
            {
                label: "Due",
                data: allDates.map(date => beforeToday.find(item => format(item.due_date, "yyyy-MM-dd") === date) ? totalsByDate[date] : 0),
                backgroundColor: 'rgba(4, 186, 71, 0.8)',
                borderWidth: 1,
                barThickness: 5,
                stack: 'stack1',
            },
            {
                label: "Upcoming",
                data: allDates.map(date => afterToday.filter(item => format(item.due_date, "yyyy-MM-dd") === date).reduce((total, item) => total + item.total, 0)),
                backgroundColor: 'rgba(255, 99, 132, 0.8)',
                borderWidth: 1,
                barThickness: 5,
                stack: 'stack1',
            },
        ],
    };

    const today = format(new Date(), "yyyy-MM-dd");

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: "time",
                stacked: true,
                time: { unit: timeUnit, tooltipFormat: "MMM dd, yyyy" },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: dateRange > 90 ? 6 : dateRange > 30 ? 8 : 10,
                    callback: (value) => {
                        const date = new Date(value);
                        return timeUnit === "month" ? format(date, "MMM yyyy") : format(date, "dd MMM");
                    },
                },
                grid: { display: false },
            },
            y: {
                stacked: false, // Allow bars to go below the X-axis
                beginAtZero: true, // Ensures the zero line stays fixed
                ticks: {
                    callback: (value) => `$${value.toLocaleString()}`,
                },
                grid: { drawBorder: true, drawOnChartArea: true }, // Ensures the zero line is visible
            },
        },
        plugins: {
            annotation: {
                annotations: {
                    line1: {
                        type: "line",
                        xScaleID: "x",
                        xMin: today,
                        xMax: today,
                        borderColor: "black",
                        borderWidth: 2,
                        borderDash: [3, 3],
                        yMin: 0,
                        yMax: Math.max(...Object.values(totalsByDate)),
                        label: {
                            display: true,
                            content: "Today",
                            enabled: true,
                            position: "end",
                            color: "black",
                            backgroundColor: "white",
                            padding: 4,
                            borderRadius: 4,
                        },
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const matches = data.filter(d => format(new Date(d.due_date), "yyyy-MM-dd") === format(new Date(tooltipItem.label), "yyyy-MM-dd"));
                        return matches.map(obj => `Invoice ${obj.invoice_id}: $${obj.total.toLocaleString()}`);
                    },
                },
                mode: "nearest",
                caretSize: 5,
            },
            legend: {
                position: "right",
                align: "start",
                labels: { boxWidth: 20, boxHeight: 5, padding: 8 },
            },
        },
    };

    return (
        <div style={{ width: "95%", height: "95%", marginTop: "0px", maxHeight: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default TimeseriesBarChart;
